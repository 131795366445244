import {useEffect} from "react";
import {AppBar, Button, IconButton, Theme, Toolbar, Typography} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import HomeIcon from '@mui/icons-material/Home';
import {useHistory} from "react-router-dom";
import {LOCALSTORAGE_TOKEN_ADMIN, API_BASE_URL} from "../../Constants";
import { useGlobalState } from "state-pool";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: '10px', //theme.spacing(2),
      },
      title: {
        flexGrow: 1,
        // display: 'none',
        // [theme.breakpoints.up('sm')]: {
        //   display: 'block',
        // },
        cursor: 'pointer',
      },
    }),
);

export const Header = () => {
  const classes = useStyles();
  const history = useHistory();

  const [loggedIn, setLoggedIn] = useGlobalState("loggedInAdmin");

  const token = localStorage.getItem(LOCALSTORAGE_TOKEN_ADMIN)
  setLoggedIn(token == null ? false : true);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN_ADMIN)
    setLoggedIn(token == null ? false : true);
  },[setLoggedIn])

  const logout = () => {
    localStorage.removeItem(LOCALSTORAGE_TOKEN_ADMIN);
    setLoggedIn(false);
    history.push("/admin/login");
  }

  const loginUrl = `${API_BASE_URL}/public/oauth/google`;

  return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => history.push("/admin")}>
              <HomeIcon />
            </IconButton>
            
            <Typography variant="h6" className={classes.title} onClick={() => history.push("/admin")}>
              admin
            </Typography>

            {!loggedIn 
              ? <Button color="inherit" href={loginUrl}>Login</Button>
              : <Button color="inherit" onClick={() => logout()}>Logout</Button>
            }

          </Toolbar>
        </AppBar>
      </div>
  )
};
