import {FC, useState, useEffect} from "react";
import {http} from "../../http";
import {API_BASE_URL} from "Constants";
import {Hub} from "Types/App"
import HubsTable from "Components/Sponsor/HubsTable"
import Footer from "Components/Footer"
import {useHistory} from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid'
import ErrorBanner from "Components/ErrorBanner"

type HubsPageProps = {}

interface HubsResponse {
  hubs: Hub[],
}

export const HubsPage: FC<HubsPageProps> = (props) => {
  const history = useHistory()

  const [error, setError] = useState<string>("");
  const [hubs, setHubs] = useState<Hub[]>([]);

  useEffect(() => {
    getHubs()
  },[])

  const getHubs = async () => {
    try {
      const response = await http<HubsResponse>(API_BASE_URL + "/sponsor/hubs", "GET");
      const body = response.parsedBody
      if (!body) {
        setError('response error')
        return
      }
      setHubs(body.hubs)
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  return (
    <div>
      <div className="container mx-auto py-10 pt-20 sm:pt-10">
        <ErrorBanner error={error}></ErrorBanner>
        <h1 className="mb-6 text-center text-5xl text-gray-600 font-black">
          店舗一覧
        </h1>
        <div className="flex justify-center sm:justify-end w-full">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-7"
            onClick={() => history.push("/sponsor/hubs/add")}
          >
            <div className="flex items-center">
              <PlusIcon className="w-4 h-4 mr-1" />店舗を追加する
            </div>
          </button>
        </div>
        <HubsTable hubs={hubs}></HubsTable>
      </div>
      <Footer></Footer>
    </div>
  )

};
