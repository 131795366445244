import {FC, useState} from "react";
import {useHistory} from 'react-router-dom';
import {http} from "../../http";
import {API_BASE_URL} from "Constants";
import HubsAddForm from "Components/Sponsor/HubsAddForm"
import { SubmitHandler, useForm } from "react-hook-form";
import { DEFAULT_USER_IMAGE } from '../../Constants';

type HubsAddPageProps = {}

export type FormState = {
  name: string,
  state: string;
  city: string;
  address_line_1: string;
  address_line_2: string;
  postal_code: string;
  image: string;
  lat: number,
  lng: number,
}

interface HubsAddResponse {
}

export const HubsAddPage: FC<HubsAddPageProps> = (props) => {
  const [error, setError] = useState<string>("");

  const history = useHistory();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
  });

  const handleFormSubmit: SubmitHandler<FormState> = async (data) => {
    try {
      await getGeocode(data.state + ' ' + data.city + ' ' + data.address_line_1)
      console.log(data.image)
      if (data.image === undefined) {
        data.image = DEFAULT_USER_IMAGE
      }

      await http<HubsAddResponse>(API_BASE_URL + "/sponsor/hubs/create", "POST",{
        "name": data.name,
        "state": data.state,
        "city": data.city,
        "address_line_1": data.address_line_1,
        "address_line_2": data.address_line_2,
        "postal_code": data.postal_code,
        "image": data.image,
        "lat": getValues("lat"),
        "lng": getValues("lng"),
      });
      history.push("/sponsor/hubs");
    } catch (ex: any) {
      if (ex === "ZERO_RESULTS") {
      } else {
        ex.forEach((e: string) => {
          if (e === "lng" || e === "lat") {
            setError("番地の詳細を入力してください。")
          } else {
            setError(ex.toString());
          }
        })
      }
    } finally {
    }
  };

  const getGeocode = (address: string) =>  {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          if (results != null && results[0]) {
            const location = results[0].geometry.location;
            setValue("lat", location.lat())
            setValue("lng", location.lng())
            resolve(status)
          }
        } else {
          reject(status)
        }
      });
    })
  }

  return (
    <HubsAddForm
    handleFormSubmit={handleFormSubmit}
    handleSubmit={handleSubmit}
    register={register}
    watch={watch}
    errors={errors}
    setValue={setValue}
    getValues={getValues}
    setError={setError}
    error={error}
    />
  )

};
