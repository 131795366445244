import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";


export default function ImageCropModal({src, setValue, name, aspectRatio, isShow, setIsShow} : {src: string, setValue: any, name: string, aspectRatio?: number, isShow: boolean, setIsShow: React.Dispatch<React.SetStateAction<boolean>>}) {
  const [cropper, setCropper] = useState<any>();
  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setValue(name, cropper.getCroppedCanvas().toDataURL());
    }
  };
  return (
    <Transition.Root show={isShow} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setIsShow}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  画像のクロップ
                </Dialog.Title>
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  initialAspectRatio={1}
                  src={src}
                  aspectRatio={aspectRatio ? aspectRatio : 16 / 9}
                  viewMode={1}
                  minCropBoxHeight={100}
                  minCropBoxWidth={100}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              </div>
              <div className="mt-5 sm:mt-6 grid grid-cols-1 sm:grid-cols-2 gap-3">
                <button
                  type="button"
                  className="order-last sm:order-1 inline-flex justify-center rounded-md border border-primary shadow-sm px-4 py-2 bg-white text-base font-medium text-primary hover:bg-white"
                  onClick={() => setIsShow(false)}
                >
                  キャンセル
                </button>
                <button
                type='button'
                className='sm:order-2 rounded-md border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary_darker'
                onClick={() => {getCropData(); setIsShow(false)}}
                >
                  決定
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
