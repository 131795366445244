import {FC, useState, useEffect} from "react";
import {http} from "../../../http";
import {API_BASE_URL} from "Constants";
import Table from "Components/Table";
import ErrorBanner from "Components/ErrorBanner";

type CouponUsedPageProps = {}

interface CouponUsedResponse {
  result: any[],
}

export const UsedList: FC<CouponUsedPageProps> = (props) => {

  const [error, setError] = useState<string>("");
  const [result, setResult] = useState<any[]>([]);

  useEffect(() => {
    getHubs()
  },[])

  const getHubs = async () => {
    try {
      const response = await http<CouponUsedResponse>(API_BASE_URL + "/users/coupons/used/list", "GET");
      const body = response.parsedBody
      if (!body) {
        setError('response error')
        return
      }
      setResult(body.result)
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  const headers = ["クーポン", "時刻", "店舗QRコード使用"]
  const data: React.ReactNode[][] = [];
  result.forEach((r) => {
    const row: React.ReactNode[] = [];
    row.push(
      <div>
        <img className="h-10 w-10 rounded-full object-cover" src={r.image} alt="hub" />
        {r.title}
      </div>
    );
    row.push(<div className="text-sm text-gray-500">{r.created}</div>);
    row.push(
      <div className="text-sm text-gray-500">
        {r.from_qr_code ? "はい" : "いいえ"}
      </div>
    );
    data.push(row);
  });

  return (
    <>
      <div className="px-5 sm:px-10">
        <ErrorBanner error={error}></ErrorBanner>
      </div>
      <Table headers={headers} data={data}></Table>
    </>
  )
};
