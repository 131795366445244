import imageCompression from "browser-image-compression"
import { GOOGLE_GEOCODE_API_KEY } from "Constants";
import { useCallback, useEffect, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { AddressComponent } from "Types/App";
import { http } from "./http";

export const convertBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 640,
      useWebWorker: true
    }
    imageCompression(file, options)
      .then(function (compressedFile) {

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result);
        }

        fileReader.onerror = (error) => {
          reject(error);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  })
}

export const validateEmail = (email: String) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// レスポンスに応じてフィールドを補完している
export const autoCompleteFields = async (postal_code: string, setValue: UseFormSetValue<any>, setError: any) => {
  const address_components: AddressComponent[] = await getAddressByPostalCode(postal_code, setError)
  let address_line_1 = ""
  let state = ""
  let city = ""
  address_components.forEach((e: AddressComponent) => {
    if (e.types.includes("sublocality_level_4")) {
      address_line_1 = e.long_name + address_line_1
    } else if (e.types.includes("sublocality_level_3")) {
      address_line_1 = e.long_name + address_line_1
    } else if (e.types.includes("sublocality_level_2")) {
      address_line_1 = e.long_name + address_line_1
    } else if (e.types.includes("sublocality_level_1")) {
      address_line_1 = e.long_name + address_line_1
    } else if (e.types.includes("locality")) {
      city = e.long_name + city
    } else if (e.types.includes("administrative_area_level_2")) {
      city = e.long_name + city
    } else if (e.types.includes("administrative_area_level_1")) {
      state = e.long_name + state
    }
  })
  setValue("address_line_1", address_line_1)
  setValue("state", state)
  setValue("city", city)
}

export const getAddressByPostalCode = async (postal_code: string, setError: React.Dispatch<React.SetStateAction<string>>) => {
  try {
    const response = await http<any>(`https://maps.googleapis.com/maps/api/geocode/json?address=${postal_code}&language=ja&components=country:JP&key=${GOOGLE_GEOCODE_API_KEY}`, "GET");
    const body = response.parsedBody
    if (!body) {
      setError('response error')
      return
    }
    return body.results[0].address_components;
  } catch (ex: any) {
    setError(ex.toString());
  } finally {
  }
};

export const useWindowDimensions = () => {
  const isClient = typeof window === 'object';
  const getWindowDimensions = useCallback(() => {
    return {
      width: isClient ? window?.innerWidth : 0,
      height: isClient ? window?.innerHeight : 0
    };
  }, [isClient]);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [getWindowDimensions]);
  return windowDimensions;
};