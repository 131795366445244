import { IdentificationIcon } from "@heroicons/react/24/outline";
import ErrorBanner from "Components/ErrorBanner";
import { ImageInput } from "Components/ImageInput";
import { Input } from "Components/Input";
import { UseFormHandleSubmit, UseFormRegister } from "react-hook-form";
import { FormState } from "./CouponsEditPage";
import { DateInput } from '../../Components/DateInput';

const CouponsEditForm = ({ handleFormSubmit, handleSubmit, register, errors, getValues, setValue, watch, error }: {
  handleFormSubmit: any, handleSubmit: UseFormHandleSubmit<FormState>,
  register: UseFormRegister<FormState>, errors: any, getValues: any, setValue: any, watch: any, error: string
}) => {
  return (
    <>
      <div className=" bg-white h-full px-4 py-5 sm:p-6">
        <h1 className="mt-6 mb-8 text-3xl text-center">クーポン編集</h1>
        <ErrorBanner error={error}></ErrorBanner>
        <form method="POST" onSubmit={handleSubmit(handleFormSubmit)}>

          {/* タイトル */}
          <div className="grid grid-cols-6 gap-7">
            <div className="col-span-6 sm:col-start-2 sm:col-span-4">
              <Input
                label="クーポン名"
                name="title"
                register={register}
                required="クーポン名を入力してください"
                maxLength={{ value: 60, message: "クーポン名が長すぎます。" }}
                minLength={{ value: 2, message: "クーポン名が短すぎます。" }}
                pattern={{ value: /.+/, message: "クーポン名の形式が不正です。" }}
                password={false}
                autoComplete="title"
                placeholder=""
                icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                errors={errors.title}
              />
            </div>

            {/* 説明 */}
            <div className="col-span-6 sm:col-start-2 sm:col-span-4">
              <Input
                label="説明"
                name="description"
                register={register}
                required="説明を入力してください"
                maxLength={{ value: 200, message: "説明が長すぎます。" }}
                minLength={{ value: 1, message: "説明が短すぎます。" }}
                pattern={{ value: /.+/, message: "説明の形式が不正です。" }}
                password={false}
                autoComplete="description"
                placeholder="3000円以上お買い上げの方は5%オフになるクーポンです！"
                icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                errors={errors.description}
              />
            </div>

            {/*  クーポン画像 */}
            <div className="col-span-6 sm:col-start-2 sm:col-span-4">
              <ImageInput label="クーポン画像" required={true} name="image" watch={watch} getValues={getValues} setError setValue={setValue}></ImageInput>
            </div>

              {/* 期間開始 */}
              <div className="col-span-6 sm:col-start-2 sm:col-span-2">
                <DateInput
                label="開始有効期限"
                required={true}
                name="period_start"
                watch={watch}
                setValue={setValue}
                 />
              </div>

              {/* 期間終了 */}
              <div className="col-span-6 sm:col-span-2">
                <DateInput
                label="終了有効期限"
                required={true}
                name="period_end"
                watch={watch}
                setValue={setValue}
                 />
              </div>

            {/* 発行数 */}
            <div className="col-span-6 sm:col-start-2 sm:col-span-4">
              <Input
                label="発行数"
                name="stock"
                register={register}
                required="発行数を入力してください"
                maxLength={{ value: 5, message: "発行数が長すぎます。" }}
                minLength={{ value: 1, message: "発行数が短すぎます。" }}
                pattern={{ value: /^[0-9]+$/, message: "発行数の形式が不正です。" }}
                password={false}
                autoComplete=""
                placeholder="100"
                icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                errors={errors.stock}
              />
            </div>
          </div>
          <div className="mt-8 grid grid-cols-6 gap-4">
            <button
              type="submit"
              className="col-span-6 sm:col-start-2 sm:col-span-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              更新
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default CouponsEditForm;