import {FC} from "react";
import {Route, Switch} from "react-router-dom";
import {CouponPage} from "./Pages/Public/CouponPage";
import Dashboard from "./Pages/User/Dashboard";
import {ProfileEditPage} from "./Pages/User/ProfileEditPage";

type AppRoutesUserProps = {}

export const AppRoutesUser: FC<AppRoutesUserProps> = (props) => {
  return (
    <Switch>
      <Route exact={true} path="/users" component={Dashboard}/>
      <Route path="/users/coupons/:id" component={CouponPage}/>
      <Route path="/users/profile/edit" component={ProfileEditPage}/>
      <Route path="/users/profile" component={Dashboard}/>
    </Switch>
  )
};
