import { useState, Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link, useHistory } from 'react-router-dom'
import { useGlobalState } from "state-pool"
import { ToLogin, ToLogout } from "../Common"
import LoginModal from "Components/User/LoginModal"
import { WEB_BASE_URL } from "Constants"

export const Header = () => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [loggedIn, ] = useGlobalState("loggedInUser");
  const history = useHistory();
  const [isShow, setIsShow] = useState(false)

  return (
    <Popover className="relative bg-white">
      <LoginModal isShow={isShow} setIsShow={setIsShow}></LoginModal>
      <div className="flex justify-between items-center px-4 py-4 ">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <Link to="/">
            <img src="/static/img/logo.png" alt="ビーハッピー! Boomin'(ブーミン)" width="98px" />
          </Link>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>

        {/* デスクトップ */}
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          {!loggedIn ? (
            <button onClick={() => ToLogin(setIsShow)} className="inline-flex items-center px-4 py-2">
              アカウント作成・ログイン
            </button>
          ) : window.location.href === `${WEB_BASE_URL}/users/profile` ? (
            <></>
          ) : (
            <>
              <button onClick={() => history.push("/users/profile", {tab: "profile"})} className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-700">マイページ</button>
              <button onClick={() => history.push("/users/profile", {tab: "favorite"})} className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-700 ml-8">お気に入り</button>
              <button onClick={() => ToLogout()} className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-700 ml-8">ログアウト</button>
            </>
          )}
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  メニュー
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            {/* モバイル */}
            {!loggedIn ? (
              <div className="px-2 pt-2 pb-3">
                <button onClick={() => ToLogin(setIsShow)} className="block w-full px-5 py-3 text-center">
                  アカウント作成・ログイン
                </button>
              </div>
            ) : window.location.href === `${WEB_BASE_URL}/users/profile` ? (
              // マイページ用のタブメニュー。今はトップのメニューと同じだが、今後"ダッシュボートのタブが多いがトップページなどのヘッダーにリンクを置く必要がない"場合はこちらに足す。
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  <button onClick={() => history.push("/users/profile")} className="text-left text-base font-medium text-gray-500 hover:text-gray-700">マイページ</button>
                  <button onClick={() => history.push("/users/profile", {tab: "favorite"})} className="text-left text-base font-medium text-gray-500 hover:text-gray-700">お気に入り</button>
                  <button onClick={() => ToLogout()} className="text-left text-base font-medium text-gray-500 hover:text-gray-700">ログアウト</button>
                </div>
              </div>
            ) : (
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  <button onClick={() => history.push("/users/profile", {tab: "profile"})} className="text-left text-base font-medium text-gray-500 hover:text-gray-700">マイページ</button>
                  <button onClick={() => history.push("/users/profile", {tab: "favorite"})} className="text-left text-base font-medium text-gray-500 hover:text-gray-700">お気に入り</button>
                  <button onClick={() => ToLogout()} className="text-left text-base font-medium text-gray-500 hover:text-gray-700">ログアウト</button>
                </div>
              </div>
            )}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
