
export const DateInput = ({label, required, name, watch, setValue}:
  {label: string, required: boolean, name: string, watch: any, setValue: any}) => {
    let value = watch(name);
  return (
    <>
      <div className="flex items-center">
        <label htmlFor="date" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        {required &&
          <div className="flex justify-center items-center text-xs text-center ml-2 w-8 h-4 text-red-400 rounded border-red-400 border">
            <p>必須</p>
          </div>
        }
      </div>
      <div className="mt-1 flex rounded-md shadow-sm">
        <input
          type="date"
          id="date"
          value={value !== undefined ? value.slice(0, -10) : new Date().toISOString().split('T')[0]} // T00:00:00Zを削除して代入している
          onChange={(e) => setValue(name, e.target.value + "T00:00:00Z")}
          className="h-9 px-2 focus:ring-secondary focus:border-secondary block w-full sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </>
  )
}