import { http } from "../../http";
import { API_BASE_URL } from "Constants"
import { Coupon } from "Types/App";
import { ToLogin } from "../../Components/Common";
import { HeartIcon } from '@heroicons/react/24/outline'

const FavoriteButton = ({ couponId, setIsShow }: { couponId: Coupon["id"], setIsShow: React.Dispatch<React.SetStateAction<boolean>> }) => {

  const handleFav = async () => {
    try {
      await http(API_BASE_URL + `/users/coupons/favorites/add`, "POST", {
        "coupon_id": couponId,
      })
      alert("お気に入りに追加しました")
    } catch (ex: any) {
      if (ex.toString() === "need login") {
        ToLogin(setIsShow);
        return
      }
      alert(ex.toString())
    } finally {
    }
  }
  return (
    <button
      type="button"
      className="w-full p-2 flex items-center justify-center text-pink-500 bg-white rounded-full"
      onClick={handleFav}
    >
      <HeartIcon className="w-5 h-5 inline-block" />
    </button>
  )
}

export default FavoriteButton;
