import { EnvelopeIcon, PhoneIcon, MapPinIcon, IdentificationIcon } from "@heroicons/react/24/solid"
import ErrorBanner from "Components/ErrorBanner";
import { Input } from "Components/Input"
import { UseFormGetValues, UseFormHandleSubmit, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { ImageInput } from "Components/ImageInput";
import { InputPostalCode } from "Components/PostalCodeInput";
import { DateInput } from '../../DateInput';

const ProfileEditForm = ({handleFormSubmit, handleSubmit, register, errors, setValue, getValues, gender,
 watch, setError, error}: {handleFormSubmit: any, handleSubmit: UseFormHandleSubmit<any>,
   register: UseFormRegister<any>, errors: any, setValue: UseFormSetValue<any>, getValues: UseFormGetValues<any>,
  gender: string, watch: any, setError: any, error: string}) => {

  return (
    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">基本プロフィール</h3>
          <p className="mt-1 text-sm text-gray-500">氏名や住所、メールアドレスなどの情報を入力してください</p>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form method="POST" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="grid grid-cols-6 gap-6">
              {/* ユーザーネーム */}
              <div className="col-span-6 sm:col-span-6">
                <Input
                  label="ユーザーネーム"
                  name="user_name"
                  register={register}
                  required="ユーザーネームを入力してください"
                  maxLength={{ value: 20, message: "ユーザーネームが長すぎます。" }}
                  minLength={{ value: 1, message: "ユーザーネームが短すぎます。" }}
                  pattern={{ value: /.+/, message: "ユーザーネームの形式が不正です。" }}
                  password={false}
                  autoComplete="user_name"
                  placeholder="例）Boomin'"
                  icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.user_name}
                />
              </div>

              <ImageInput label="プロフィール画像" required={false} name="profile_image" aspectRatio={1 / 1} watch={watch} getValues={getValues} setError setValue={setValue}></ImageInput>

              {/* 名字 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="姓"
                  name="last_name"
                  register={register}
                  required="姓を入力してください"
                  maxLength={{ value: 20, message: "姓が長すぎます。" }}
                  minLength={{ value: 1, message: "姓が短すぎます。" }}
                  pattern={{ value: /^\D+$/, message: "姓の形式が不正です。" }}
                  password={false}
                  autoComplete="last_name"
                  placeholder="例）田中"
                  icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.last_name}
                />
              </div>

              {/* 名前 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="名"
                  name="first_name"
                  register={register}
                  required="名を入力してください"
                  maxLength={{ value: 20, message: "名が長すぎます。" }}
                  minLength={{ value: 1, message: "名が短すぎます。" }}
                  pattern={{ value: /^\D+$/, message: "名の形式が不正です。" }}
                  password={false}
                  autoComplete="first_name"
                  placeholder="例）太郎"
                  icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.first_name}
                />
              </div>

              {/* 性別 */}
              <div className="col-span-6">
                <label className="text-base font-medium text-gray-900">性別</label>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  {[{ id: "male", title: '男性' }, { id: "female", title: '女性' }].map((radio) => (
                    <div key={radio.id} className="flex items-center">
                      <input
                        id={radio.id}
                        type="radio"
                        onChange={() => setValue("gender", radio.id)}
                        checked={gender === radio.id}
                        className="focus:ring-secondary h-4 w-4 text-primary border-gray-300"
                      />
                      <label htmlFor={radio.id} className="ml-3 block text-sm font-medium text-gray-700">
                        {radio.title}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* 生年月日 */}
              <div className="col-span-6 sm:col-span-4">
                <DateInput
                label="生年月日"
                required={true}
                name="birth_date"
                watch={watch}
                setValue={setValue}
                />
              </div>

              {/* メールアドレス */}
              <div className="col-span-6 sm:col-span-4">
                <Input
                  label="メールアドレス"
                  name="email"
                  register={register}
                  required="メールアドレスを入力してください"
                  maxLength={{ value: 2048, message: "メールアドレスが長すぎます" }}
                  minLength={{ value: 6, message: "メールアドレスが短すぎます" }}
                  pattern={{ value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: "メールアドレスの形式が不正です。" }}
                  password={false}
                  autoComplete="email"
                  placeholder="example@gmail.com"
                  icon={<EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.email}
                />
              </div>

              {/* 電話番号 */}
              <div className="col-span-6 sm:col-span-4">
                <Input
                  label="電話番号"
                  name="phone"
                  register={register}
                  required="電話番号を入力してください"
                  maxLength={{ value: 20, message: "電話番号が長すぎます" }}
                  minLength={{ value: 8, message: "電話番号が短すぎます" }}
                  pattern={{ value: /^[0-9]+$/, message: '電話番号は全て半角数字で入力してください。' }}
                  password={false}
                  autoComplete="tel"
                  placeholder="例) 08012345678"
                  icon={<PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.phone}
                />
              </div>

              {/* 郵便番号 */}
              <div className="col-span-4 sm:col-span-4">
                <InputPostalCode
                  register={register}
                  required="郵便番号を入力してください"
                  errors={errors.postal_code}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                />
              </div>

              {/* 都道府県 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="都道府県"
                  name="state"
                  register={register}
                  required="都道府県名を入力してください"
                  maxLength={{ value: 6, message: "都道府県名が長すぎます" }}
                  minLength={{ value: 1, message: "都道府県名が短すぎます" }}
                  pattern={{ value: /^[ぁ-んァ-ヶー一-龠]+$/, message: '都道府県名の形式が不正です。' }}
                  password={false}
                  autoComplete="state"
                  placeholder="例) 東京都"
                  icon={<MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.state}
                />
              </div>

              {/* 市区町村名 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="市区町村名"
                  name="city"
                  register={register}
                  required="市区町村名を入力してください"
                  maxLength={{ value: 11, message: "市区町村名が長すぎます" }}
                  minLength={{ value: 1, message: "市区町村名が短すぎます" }}
                  pattern={{ value: /^[ぁ-んァ-ヶー一-龠]+$/, message: '市区町村名の形式が不正です。' }}
                  password={false}
                  autoComplete="city"
                  placeholder="例) 渋谷区"
                  icon={<MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.city}
                />
              </div>

              {/* 番地 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="番地"
                  name="address_line_1"
                  register={register}
                  required="番地を入力してください"
                  maxLength={{ value: 20, message: "番地が長すぎます" }}
                  minLength={{ value: 1, message: "番地が短すぎます" }}
                  pattern={{ value: /.+/, message: '番地の形式が不正です。' }}
                  password={false}
                  autoComplete="address_level3"
                  placeholder="例) 恵比寿西2丁目20-17"
                  icon={<MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.address_line_1}
                />
              </div>

              {/* 番号 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="番号"
                  name="address_line_2"
                  register={register}
                  required={false}
                  maxLength={{ value: 10, message: "番号が長すぎます" }}
                  minLength={{ value: 1, message: "番号が短すぎます" }}
                  pattern={{ value: /.+/, message: '番号の形式が不正です。' }}
                  password={false}
                  autoComplete="address_level4"
                  placeholder="例) 202"
                  icon={<MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.address_line_2}
                />
              </div>

            </div>
            <ErrorBanner error={error}></ErrorBanner>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 pt-10">
              <button
                type="submit"
                className="sm:order-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
              >
                更新する
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ProfileEditForm;