import { LOCALSTORAGE_TOKEN_USER, WEB_BASE_URL } from "../Constants"

export const ToLogout = () => {
  localStorage.removeItem(LOCALSTORAGE_TOKEN_USER);
  const path = window.location.href.replace(WEB_BASE_URL, "");
  window.location.href = `${WEB_BASE_URL}${path}`;
}

export const ToLogin = (setIsShow: React.Dispatch<React.SetStateAction<boolean>>) => {
  const path = window.location.href.replace(WEB_BASE_URL, "");
  localStorage.setItem("nextPage", path);
  setIsShow(true);
  //window.location.href = `${API_BASE_URL}/public/oauth/instabase`;
}
