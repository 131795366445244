import {FC} from "react";
import {Route, Switch, Redirect, RouteProps} from "react-router-dom";
import {IndexPage} from "./Pages/Admin/IndexPage";
import {LoginPage} from "./Pages/Admin/LoginPage";
import {SponsorsPage} from "./Pages/Admin/SponsorsPage";
import {LOCALSTORAGE_TOKEN_ADMIN} from "./Constants";
import {useGlobalState} from "state-pool";

type AppRoutesProps = {}

export interface IPrivateRouteProps extends RouteProps {
  redirectPath: string
}

const PrivateRoute: FC<IPrivateRouteProps> = (props) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN_ADMIN);
  const isAuth = token ? true : false;
  return isAuth ? (
   <Route {...props} component={props.component} render={undefined} />
 ) : (
   <Redirect to={{pathname: props.redirectPath}} />
 )
}

export const AppRoutesAdmin: FC<AppRoutesProps> = (props) => {
  const [, setLoggedIn] = useGlobalState("loggedInAdmin");
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN_ADMIN);
  const isAuth = token ? true : false;
  setLoggedIn(isAuth)
  return (
    <Switch>
      <Route exact={true} path="/admin" component={IndexPage}/>
      <Route path='/admin/login' component={LoginPage}/>
      <PrivateRoute redirectPath="/admin/login" path='/admin/sponsors' component={SponsorsPage}/>
    </Switch>
  )
};
