import { Fragment, useState } from "react";
import {useHistory} from "react-router-dom";
import QRCode from "qrcode.react"
import {WEB_BASE_URL, API_BASE_URL} from "Constants";
import { Dialog, Transition } from '@headlessui/react';


const HubsTable = (props) => {

  const history = useHistory();

  const [hubId, setHubId] = useState(null);
  const [couponId, setCouponId] = useState(null);
  const [couponImage, setCouponImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /* eslint-disable-next-line */
  const handleModalOpen = (hubId, couponId, couponImage) => {
    setHubId(hubId);
    setCouponId(couponId);
    setCouponImage(couponImage);
    setIsModalOpen(true)
  }
  const handleModalClose = () => {
    setIsModalOpen(false)
    props.setUserCode('');
  }

  let expiryDateArray = [];
  if (props.coupons[0] !== undefined) {
    props.coupons.forEach(c => {
      let expiryDate = new Date(c.period_end)
      expiryDateArray.push(`有効期限: ${expiryDate.getFullYear()}/${expiryDate.getMonth() + 1}/${expiryDate.getDate()}`)
    })
  }
    return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    クーポン名
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    詳細
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    期限
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    クーポンQRコード
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    店頭QRコード
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    編集
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.coupons.map((coupon, indice) => (
                  <tr key={coupon.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img className="h-10 w-10 rounded-full object-cover" src={coupon.image} alt="" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{coupon.title}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{coupon.description}</div>
                    </td>
                    <td>
                      {expiryDateArray[indice]}
                      <div className="text-sm text-gray-500">残り{coupon.stock}個</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <QRCode value={WEB_BASE_URL + "/public/coupons/" + coupon.id} size={50}/>
                      {/* <div>
                        <button onClick={() => handleModalOpen(coupon.hub_id, coupon.id, coupon.image)}>印刷依頼</button>
                      </div> */}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div style={{float: "left", marginRight: "10px"}}>
                        <QRCode value={WEB_BASE_URL + `/public/coupons/${coupon.id}?qr=${coupon.qr_code}`} size={50} />
                      </div>
                      <button onClick={() => props.handleStampQRcodePrintClick(coupon.hub_id, coupon.id)}>ネットプリントで印刷</button>
                      <br />
                      <button onClick={() => window.open(API_BASE_URL + `/coupons/${coupon.id}/qrcode/${coupon.qr_code}.pdf`)}>ダウンロード</button>
                      {/* <div>
                        <button onClick={() => handleModalOpen(coupon.hub_id, coupon.id, coupon.image)}>印刷依頼</button>
                      </div> */}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-blue-500">
                        <button onClick={() => history.push("/sponsor/hubs/" + coupon.hub_id + "/coupons/" + coupon.id + "/edit")}>編集</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={handleModalClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  ネットプリントで印刷
                </Dialog.Title>
                <div className="mt-2">
                  <div style={{border: "1px #000 solid", padding: "100px"}}>
                    <img className="h-50 w-50 rounded-full object-cover" src={couponImage} alt="" />
                    <QRCode value={WEB_BASE_URL + "/public/coupons/" + couponId} size={100}/>
                  </div>

                  <p className="text-gray-500 mt-4">
                    <div style={{padding: "30px"}}>
                      ユーザー番号: {props.userCode}
                    </div>
                    <button
                      type="button"
                      className="w-full bg-primary border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary"
                      onClick={() => props.handlePrintClick(hubId, couponId)}
                    >
                      番号取得
                    </button>
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="w-full bg-primary border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary"
                    onClick={handleModalClose}
                  >
                    閉じる
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
export default HubsTable;