import { MAX_IMAGE_SIZE } from "Constants"
import { useEffect, useState } from "react"
import { convertBase64 } from "utils"
import { DEFAULT_USER_IMAGE } from '../Constants';
import ImageCropModal from './ImageCropModal';

export const ImageInput = ({label, required, name, aspectRatio, watch, getValues, setError, setValue}:
  {label: string, required: boolean, name: string, aspectRatio?: number, watch: any, getValues: any, setError: any, setValue: any}) => {

  // ファイルが範囲内をドラッグされているか判別するbool
  const [dragOver, setDragOver] = useState(false)

  const [isShow, setIsShow] = useState<boolean>(false)

  const [src, setSrc] = useState<string>('')

  const img = watch(name)

  const aspect = aspectRatio ? aspectRatio : 16 / 9

  useEffect(() =>{
    if (getValues(name) === undefined) {setValue(name, DEFAULT_USER_IMAGE)};
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [getValues(name)])

  // 画像読み込みハンドラ
  const handleFileRead = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files != null) {
      const file = files.item(0)
      if (file != null) {
        if (file.size >= MAX_IMAGE_SIZE) {
          setError("画像のファイルサイズが大きすぎます");
          return;
        }
        const base64 = await convertBase64(file)
        setSrc(base64 as string)
        setIsShow(true)
      }
    }
  }

  // 画像のドロップハンドラ
  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const transferItem = e.dataTransfer.items[0];
    const entry = transferItem.webkitGetAsEntry();
    setDragOver(false)
    if (entry == null) {
      return;
    }
    if (entry.isFile) {
      const file: File = await new Promise((resolve) => {
        const newentry = entry as FileSystemFileEntry;
        newentry.file((file) => { // ※3
          resolve(file);
        });
      });
      if (file.size >= MAX_IMAGE_SIZE) {
        setError("画像のファイルサイズが大きすぎます");
        return;
      }
      const base64 = await convertBase64(file)
      setSrc(String(base64))
      setIsShow(true)

    }
  }

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true)
  }

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  }

  return (
    <>
      {/* プロフィール画像 */}
      <div className="col-span-6">
        <div className="flex items-center">
          <label className="block text-sm font-medium text-gray-700">{label}</label>
          {required &&
            <div className="flex justify-center items-center text-xs text-center ml-2 w-8 h-4 text-red-400 rounded border-red-400 border">
              <p>必須</p>
            </div>
          }
        </div>
        <div className="mt-1 flex items-center space-x-5">
          <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
            {img && <img className="object-cover w-full h-full" src={img} alt="" />}
          </span>
          <input
            accept="image/*"
            type="file"
            className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            onChange={e => {
              handleFileRead(e)
            }}
          />
        </div>
      </div>

      {/* ドラッグアンドドロップ */}
      <div className="col-span-6 sm:col-span-4">
        <div
          className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 rounded-md ${dragOver ? "border-primary border-solid" : "border-gray-300 border-dashed"}`}
          onDragOver={(event) => onDragOver(event)}
          onDrop={(event) => onDrop(event)}
          onDragLeave={(event) => onDragLeave(event)}
        >
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <p className="pl-1">ファイルをドラッグアンドドロップでも変更可能です。</p>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
          </div>
        </div>
      </div>
      <ImageCropModal src={src} setValue={setValue} name={name} aspectRatio={aspect} isShow={isShow} setIsShow={setIsShow}></ImageCropModal>
    </>
  )
}