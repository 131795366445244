import {FC} from "react";
import { Helmet } from "react-helmet-async"
import { GOOGLE_TAG_ID } from "Constants"

type HeadProps = {
  title: string | undefined,
}

const Head: FC<HeadProps> = (props) => {
    const {title} = props
    return (
      <Helmet>
        <title>{title}</title>

        <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_ID}`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${GOOGLE_TAG_ID}');
          `}
        </script>
      </Helmet>
    )
}

export default Head
