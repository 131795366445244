import { FieldError } from "react-hook-form"
interface Props {
  label: string
  name: string
  register: any
  required: boolean | string
  maxLength: {
    value: number
    message: string
  }
  minLength: {
    value: number
    message: string
  }
  pattern: {
    value: RegExp
    message: string
  }
  password: boolean
  autoComplete: string
  placeholder: string
  icon: any
  errors: FieldError | undefined
}

export const Input = (props: Props) => {
  return (
    <>
      <div className="flex items-center">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
        {props.required &&
        <div className="flex justify-center items-center text-xs text-center ml-2 w-8 h-4 text-red-400 rounded border-red-400 border">
          <p>必須</p>
        </div>
        }
      </div>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {props.icon}
        </div>
        <input
          className="h-9 px-2 focus:ring-secondary focus:border-secondary block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
          {
          ...props.register(props.name, {
            required: props.required,
            maxLength: { value: props.maxLength.value, message: props.maxLength.message },
            minLength: { value: props.minLength.value, message: props.minLength.message },
            pattern: {
              value: props.pattern.value,
              message: props.pattern.message,
            }
          })
          }
          type={props.password ? "password" : "text"}
          id={props.name}
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
        />
      </div>
      {props.errors && <p className="text-red-600 text-xs">{props.errors.message}</p>}
    </>
  )
}