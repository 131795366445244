import BeeModel from "Components/Public/BeeScene";

const HeroArea = ({ getLocation }: { getLocation: () => Promise<void> }) => (
  <>
    <div className="px-4 flex justify-between items-center">
      <p className="">
        ローカルクーポンをゲットして、<br />寄り道をもっと楽しく。
      </p>
      <div className="w-20 h-20 -mt-4">
        <BeeModel />
      </div>
    </div>
    <div className="mt-4 px-4">
      <a href="/#map">
        <button
          className="w-full flex items-center justify-center px-8 py-4 border border-transparent text-base font-bold rounded-md text-white bg-image_color"
          style={{ transition: "all 0.2s ease 0s" }}
          onClick={getLocation}
        >
          クーポンを探す
        </button>
      </a>
    </div>
  </>
);

export default HeroArea;
