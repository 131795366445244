import { IdentificationIcon } from "@heroicons/react/24/outline";
import ErrorBanner from "Components/ErrorBanner";
import { Input } from "Components/Input";
import { UseFormHandleSubmit, UseFormRegister } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormState } from "./LoginPage";

const LoginForm = ({handleFormSubmit, handleSubmit, register, errors, error}: {handleFormSubmit: any, handleSubmit: UseFormHandleSubmit<FormState>,
     register: UseFormRegister<FormState>, errors: any, error: string}) => {
  return (
    <>
      <div className=" bg-white h-full px-4 py-5 sm:p-6">
        <h1 className="mt-24 mb-8 text-3xl text-center">加盟店ログイン</h1>
        <ErrorBanner error={error}></ErrorBanner>
        <form method="POST" onSubmit={handleSubmit(handleFormSubmit)}>
          {/* メールアドレス */}
          <div className="grid grid-cols-6 gap-3">
            <div className="col-span-6 sm:col-start-2 sm:col-span-4">
              <Input
                label="メールアドレス"
                name="email"
                register={register}
                required="メールアドレスを入力してください"
                maxLength={{ value: 2048, message: "メールアドレスが長すぎます。" }}
                minLength={{ value: 6, message: "メールアドレスが短すぎます。" }}
                pattern={{ value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "メールアドレスの形式が不正です。" }}
                password={false}
                autoComplete="email"
                placeholder=""
                icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                errors={errors.email}
              />
            </div>

            {/* パスワード */}
            <div className="col-span-6 sm:col-start-2 sm:col-span-4">
              <Input
                label="パスワード"
                name="password"
                register={register}
                required="パスワードを入力してください"
                maxLength={{ value: 100, message: "パスワードが長すぎます。" }}
                minLength={{ value: 4, message: "パスワードが短すぎます。" }}
                pattern={{ value: /.+/, message: "パスワードの形式が不正です。" }}
                password={true}
                autoComplete="current-password"
                placeholder=""
                icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                errors={errors.password}
              />
            </div>
          </div>
          <div className="mt-8 text-center">
            <button
              type="submit"
              className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              ログイン
            </button>
          </div>
          <div className="mt-8 text-center">
            <Link to="/sponsor/signup" className="text-blue-500">新規登録</Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default LoginForm;