import { FC, useState, useEffect } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { http } from "../../http";
import { API_BASE_URL } from "Constants";
import ErrorBanner from "Components/ErrorBanner";
import Footer from "Components/Footer";
import { PhoneIcon, EnvelopeIcon, MapPinIcon, UserIcon} from "@heroicons/react/24/solid"
import Breadcrumbs from "Components/Breadcrumbs";
import { BreadcrumbPage } from "Types/App";

type ProfilePageProps = {}

type UserProfile = {
  user_name: string,
  profile_image: string,
  state: string,
  email: string,
  phone: string,
  last_name: string,
  first_name: string,
  birth_date: string,
  gender: string,
  city: string,
  address_line_1: string,
  address_line_2: string,
  postal_code: string,
}

interface UserProfileResponse {
  favorite_count: number,
  user: {
    user_name: string,
    profile_image: string,
    state: string,
    email: string,
    phone: string,
    first_name: string,
    last_name: string,
    birth_date: string,
    gender: string,
    city: string,
    address_line_1: string,
    address_line_2: string,
    postal_code: string,
  },
}

export const Profile: FC<ProfilePageProps> = (props) => {

  const [error, setError] = useState<string>("");
  const [favoriteCount, setFavoriteCount] = useState<number>(0);
  const [user, setUser] = useState<UserProfile>({
    user_name: "",
    profile_image: "",
    state: "",
    email: "",
    phone: "",
    last_name: "",
    first_name: "",
    birth_date: new Date().toISOString().split('T')[0],
    gender: "",
    city: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
  });

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getHub = async () => {
      try {
        const response = await http<UserProfileResponse>(API_BASE_URL + `/users/profile`, "GET");
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        setFavoriteCount(body.favorite_count);
        setUser({
          user_name: body.user.user_name,
          profile_image: body.user.profile_image,
          state: body.user.state,
          email: body.user.email,
          phone: body.user.phone,
          last_name: body.user.first_name,
          first_name: body.user.last_name,
          birth_date: body.user.birth_date,
          gender: body.user.gender,
          city: body.user.city,
          address_line_1: body.user.address_line_1,
          address_line_2: body.user.address_line_2,
          postal_code: body.user.postal_code,
        })

        // setUser(body.user);
      } catch (ex: any) {
        setError(ex.toString());
      } finally {
      }
    };
    getHub();
  }, [id])

  const pages: BreadcrumbPage[] = [
    { name: "プロフィール", href: `/users/profile`, current: true },
  ]

  return (
    <>
      <div className="bg-white">
        <div className="px-5 sm:px-10">
          <ErrorBanner error={error}></ErrorBanner>
        </div>
        <div className="flex items-center ml-5 sm:ml-0 sm:px-10 sm:flex sm:bg-white sm:bg-none py-7 pb-0">
          <div className="w-32 h-32 sm:w-40 sm:h-40 flex-shrink-0 relative">
            {user.profile_image ?
            <img className="absolute rounded-full mx-auto w-full h-full object-cover" src={user.profile_image} alt="user profile" />:
            <img className="absolute rounded-full mx-auto w-full h-full object-cover" src="/static/img/default_profile_image.png" alt="user profile" />
            }
          </div>
            <h4 className="ml-5 text-center sm:text-left text-4xl font-bold">{user.user_name}</h4>
        </div>
        {/* body */}
        <div className="px-5 sm:px-10">
          </div>
        <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 gap-4 px-5 sm:px-10">
          <div className="text-primary rounded-lg p-3 pb-4 font-bold">お気に入りクーポン数: {favoriteCount}</div>
          <div></div>
          <div className="flex bg-gray-100 p-3 py-4 rounded-lg"><MapPinIcon className="w-5 mr-2 text-gray-500"></MapPinIcon>{user.state}</div>
          <div className="flex bg-gray-100 p-3 py-4 rounded-lg"><EnvelopeIcon className="w-5 mr-2 text-gray-500"></EnvelopeIcon>{user.email}</div>
          <div className="flex bg-gray-100 p-3 py-4 rounded-lg"><PhoneIcon className="w-5 mr-2 text-gray-500"></PhoneIcon>{user.phone}</div>
          <div className="flex bg-gray-100 p-3 py-4 rounded-lg"><UserIcon className="w-5 mr-2 text-gray-500"></UserIcon>{user.gender === "male" ? "男性" : "女性"}</div>
        </div>
        <div className="px-5 sm:px-10 pb-24 mt-20">
          <button
            className="w-full sm:w-1/3 px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary_darker md:py-3 md:text-lg md:px-10"
            style={{ transition: "all 0.2s ease 0s" }}
            onClick={() => history.push('/users/profile/edit')}
          >
            編集する
          </button>
        </div>
        <div className="px-5 sm:px-10">
          <Breadcrumbs pages={pages}></Breadcrumbs>
        </div>
      </div>
      <Footer></Footer>
    </>
  )

};