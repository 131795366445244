import {FC, useState, useEffect} from "react";
import {useParams, useHistory} from 'react-router-dom';
import {http} from "../../http";
import {API_BASE_URL} from "Constants";
import CouponsTable from "Components/Sponsor/CouponsTable"
import {Coupon} from "Types/App"
import Footer from "Components/Footer"
import { PlusIcon } from '@heroicons/react/24/solid'
import ErrorBanner from "Components/ErrorBanner"

type CouponsPageProps = {}

interface CouponsResponse {
  coupons: Coupon[],
}

export const CouponsPage: FC<CouponsPageProps> = () => {
  const history = useHistory()
  const [error, setError] = useState<string>("");
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [userCode, setUserCode] = useState("");

  const { hub_id } = useParams<{ hub_id: string }>();

  useEffect(() => {
    const getCoupons = async () => {
      try {
        const response = await http<CouponsResponse>(API_BASE_URL + `/sponsor/hubs/${hub_id}/coupons`, "GET");
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        setCoupons(body.coupons)
      } catch (ex: any) {
        setError(ex.toString());
      } finally {
      }
    };
    getCoupons();
  },[hub_id])

  const handlePrintClick = async (hubId: number, couponId: number) => {
    try {
      const response = await http<any>(API_BASE_URL + `/sponsor/hubs/${hubId}/coupons/${couponId}/print`, "GET");
      const body = response.parsedBody
      if (!body) {
        setError('response error')
        return
      }
      setUserCode(body.user_code)
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  const handleStampQRcodePrintClick = async (hubId: number, couponId: number) => {
    try {
      const response = await http<any>(API_BASE_URL + `/sponsor/hubs/${hubId}/coupons/${couponId}/print/stamp_rally`, "GET");
      const body = response.parsedBody
      if (!body) {
        setError('response error')
        return
      }
      setUserCode(body.user_code)
      alert("ネットプリント印刷IDが発行されました。メールでお知らせします。")
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  return (
    <div className="container mx-auto py-10 pt-20 sm:pt-10 pb-30 sm:pb-0">
      <ErrorBanner error={error}></ErrorBanner>
      <h1 className="mb-6 text-center text-5xl text-gray-600 font-black">
        クーポン一覧
      </h1>
      <div className="flex justify-center sm:justify-end w-full">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-7"
          onClick={() => history.push("/sponsor/hubs/" + hub_id + "/coupons/add")}
        >
          <div className="flex items-center">
            <PlusIcon className="w-4 h-4 mr-1" />クーポンを作成
          </div>
        </button>
      </div>
      <CouponsTable coupons={coupons} handlePrintClick={handlePrintClick} handleStampQRcodePrintClick={handleStampQRcodePrintClick} userCode={userCode} setUserCode={setUserCode}></CouponsTable>
      <Footer></Footer>
    </div>
  )

};
