import { Fragment, FC, FormEvent, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { http } from "../../http";
import { API_BASE_URL } from "../../Constants";
import { Coupon, Hub } from "Types/App";
import Head from "Components/Head";
import CouponRedeemArea from "Components/User/CouponRedeemArea";
import { Dialog, Transition } from "@headlessui/react";
import { useGlobalState } from "state-pool";
import Footer from "Components/Footer";
import ErrorBanner from "Components/ErrorBanner";

type CouponPageProps = {};

interface UseCouponResponse {}

interface CouponResponse {
  hub: Hub;
  coupon: Coupon;
  available: boolean;
}

export const CouponPage: FC<CouponPageProps> = (props) => {
  const [loggedIn] = useGlobalState("loggedInUser");
  const [error, setError] = useState<string>("");
  const [hub, setHub] = useState<Hub>({
    id: 0,
    sponsor_id: 0,
    name: "",
    state: "",
    city: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
    latitude: 0,
    longitude: 0,
    image: "",
    coupons: [],
  });
  const [coupon, setCoupon] = useState<Coupon>({
    id: 0,
    title: "",
    description: "",
    image: "",
    period_start: "",
    period_end: "",
    stock: 0,
    hub_id: 0,
    hub_sponsor_id: 0,
    hub_name: "",
    hub_state: "",
    hub_city: "",
    hub_address_line_1: "",
    hub_address_line_2: "",
    hub_postal_code: "",
    hub_latitude: 0,
    hub_longitude: 0,
    hub_image: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [available, setAvailable] = useState(false);
  const [qrCode, setQrCode] = useState("");

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getCoupon = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const qr = urlParams.get("qr");
        setQrCode(qr || "");
        const response = await http<CouponResponse>(
          API_BASE_URL + `/public/users/coupons/${id}`,
          "GET"
        );
        const body = response.parsedBody;
        if (!body) {
          setError("response error");
          return;
        }
        setHub(body.hub);
        setCoupon(body.coupon);
        setAvailable(body.available);
      } catch (ex) {
        if (ex instanceof Error) {
          setError(ex.toString());
        } else {
          setError("unknown error");
        }
      } finally {
      }
    };
    getCoupon();
  }, [id]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleUse = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await http<UseCouponResponse>(
        API_BASE_URL + `/users/coupons/${id}/use`,
        "POST",
        {
          coupon_id: id,
          qr_code: qrCode,
        }
      );
      handleModalOpen();
    } catch (ex) {
      if (ex instanceof Error) {
        setError(ex.toString());
      } else {
        setError("unknown error");
      }
    } finally {
    }
  };

  return (
    <>
      <Head title={`${coupon?.title}｜${hub?.name}｜Boomin'(ブーミン)`} />

      <div className="">
        <ErrorBanner error={error}></ErrorBanner>
        <CouponRedeemArea
          coupon={coupon}
          hub={hub}
          handleUse={handleUse}
          loggedIn={loggedIn}
          available={available}
        />
      </div>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {}}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="bg-gray-100 rounded-md p-6">
                  クーポンを使用しました。 こちらの画面を提示してください。
                </div>
                <div className="mt-2">
                  <div>
                    <img
                      src="/static/img/done.gif"
                      alt="クーポンを使用しました"
                    />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-2xl mb-4 font-bold leading-6 text-gray-900"
                  >
                    {coupon?.title}
                  </Dialog.Title>
                  <p className="mb-4">{coupon?.description}</p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="font-black w-full border border-image_color rounded-md py-3 px-8 flex items-center justify-center text-base text-image_color"
                    onClick={handleModalClose}
                  >
                    確認して閉じる
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <Footer />
    </>
  );
};
