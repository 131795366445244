import React, {FC, useState, useEffect} from "react";
import {Container} from "@mui/material";
import {http} from "../../http";
import {API_BASE_URL, LOCALSTORAGE_TOKEN_USER} from "Constants";
import { useLocation, useHistory } from 'react-router-dom';
import {useGlobalState} from "state-pool";
import ErrorBanner from "Components/ErrorBanner"

interface QueryString {
  [key: string]: string;
}

type CallbackPageProps = {}

interface CallbackResponse {
  token: string,
}

export const OAuthCallbackInstabasePage: FC<CallbackPageProps> = (props) => {

  const [error, setError] = useState<string>("");

  const [, setLoggedIn] = useGlobalState("loggedInUser");

  const history = useHistory();

  const search = useLocation().search;
  const query = search
    .slice(1)
    .split('&')
    .map((str) => [str.split('=')[0], str.split('=')[1]])
    .reduce((acc, a) => {
      acc[a[0]] = a[1];
      return acc;
    }, {} as QueryString);

  const callback = React.useCallback(async() => {
    try {
      const code = query['code'];
      const response = await http<CallbackResponse>(API_BASE_URL + "/public/oauth/callback/instabase?code=" + code, "GET");
      const body = response.parsedBody
      if (!body) {
        setError('response error')
        return
      }
      localStorage.setItem(LOCALSTORAGE_TOKEN_USER, body.token);
      setLoggedIn(true);
      let nextPage = localStorage.getItem("nextPage");
      if (nextPage) {
        localStorage.removeItem("nextPage");
        history.push(nextPage);
      } else {
        history.push("/");
      }
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  }, [history, query, setLoggedIn]);

  useEffect(() => {
    callback()
    // eslint-disable-next-line
  },[])

  return (
      <Container component="main" maxWidth="xl">
        <ErrorBanner error={error}></ErrorBanner>
      </Container>
  )

};
