import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/24/solid'
import { BreadcrumbPage } from "Types/App"

export default function Breadcrumbs({ pages }: { pages: BreadcrumbPage[] }) {
  return (
    <nav className="flex overflow-scroll py-4" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2 px-4">
        <li className="whitespace-nowrap">
          <div>
            <Link to="/" className="flex items-center">
              <HomeIcon className="h-5 w-5 inline-block mr-2" aria-hidden="true" />
              トップページ
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name} className="whitespace-nowrap">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>

              <Link
                to={page.href}
                className="ml-2"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
