import { FC, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { http } from "../../http";
import { API_BASE_URL } from "Constants";
import CouponCard from "Components/User/CouponCard";
import {HubResponse, Coupon, Hub } from "Types/App";
import Footer from "Components/Footer"
import Breadcrumbs from "Components/Breadcrumbs"
import { MapPinIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import Head from "Components/Head"
import ErrorBanner from "Components/ErrorBanner"

type HubPageProps = {}

export const HubPage: FC<HubPageProps> = (props) => {
  const [error, setError] = useState<string>("");
  const [hub, setHub] = useState<Hub>({
    id: 0,
    sponsor_id: 0,
    name: "",
    state: "",
    city: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
    latitude: 0,
    longitude: 0,
    image: "",
    coupons: []
  });

  const [coupons, setCoupons] = useState<Coupon[]>([]);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getCoupon = async () => {
      try {
        const response = await http<HubResponse>(API_BASE_URL + `/public/hubs/${id}`, "GET");
        let body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        setHub(body.hub);
        setCoupons(body.coupons);
      } catch (ex: any) {
        setError(ex.toString());
      } finally {
      }
    };
    getCoupon();
  }, [id])

  if (!hub.id) {
    return null
  }

  const breadcrumbs = [
    { name: hub.name, href: `/public/hubs/${hub.id}`, current: true },
  ]

  return (
    <>
      <Head title={`${hub.name}｜Boomin'`} />

      <div className="bg-white">
        <ErrorBanner error={error}></ErrorBanner>
        <main className="pb-12">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="max-w-2xl mx-auto lg:max-w-none">
              <div className="px-4 sm:px-0 pt-4">
                <div className="flex">
                  <div className="mr-2">
                    <img src={hub.image} alt={hub.name} className="h-10 w-10 rounded-full object-center object-cover" />
                  </div>
                  <div>
                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">{hub.name}</h1>
                  </div>
                </div>
              </div>

              <iframe
                title="Google Maps"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCQvbBiefRM03FZojjOhLfCAJ6MDoEIgaQ&language=ja&q=${hub.latitude},${hub.longitude}&zoom=16`}
                className="h-96 w-full mt-6"
                loading="lazy"
              />

              <div className="px-4 sm:px-0 mt-3">
                <div className="text-base text-gray-700 space-y-6">
                  <MapPinIcon className="w-5 h-5 inline-block mr-2" />
                  {hub.state}{hub.city}{hub.address_line_1} {hub.address_line_2} (<a href={`https://maps.google.com/maps?q=${hub.latitude},${hub.longitude}&zoom=16`} rel="noopener noreferrer nofollow" target="_blank">Google マップ <ArrowTopRightOnSquareIcon className="w-5 h-5 inline-block" /></a>)
                </div>
              </div>

              <div className="mt-6 px-4 sm:px-0 pt-4 sm:pt-0">
                <h2 className="text-base font-bold leading-tight text-gray-900">
                  クーポン一覧
                </h2>
                <div className="mt-4">
                  <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {coupons.map((coupon) => (
                      <div key={coupon.id} className="mx-auto w-full">
                        <CouponCard coupon={coupon} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl md:px-0 lg:px-8">
            <Breadcrumbs pages={breadcrumbs} />
          </div>
        </main>

      </div>

      <Footer />
    </>
  )
}
