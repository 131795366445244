import {LOCALSTORAGE_TOKEN_SPONSOR, LOCALSTORAGE_TOKEN_ADMIN, LOCALSTORAGE_TOKEN_USER} from "./Constants";

export interface IHttpResponse<T> extends Response {
  parsedBody?: T;
}


export const http = <T>(url: string, method: string, body?: any, contentType: string = "application/json"): Promise<IHttpResponse<T>> => {

  const request = new Request(url, {
    method: method,
    body: body ? JSON.stringify(body) : null
  });
  if (method === "POST" || method === "PUT") {
    request.headers.set("Content-Type", contentType);
  }

  let token;
  if (url.match(/admin/)) {
    token = localStorage.getItem(LOCALSTORAGE_TOKEN_ADMIN);
  } else if (url.match(/sponsor/)) {
    token = localStorage.getItem(LOCALSTORAGE_TOKEN_SPONSOR);
  } else if (url.match(/users/)) {
    token = localStorage.getItem(LOCALSTORAGE_TOKEN_USER);
  }
  if (token != null) {
    request.headers.set("Authorization", "Bearer " + token);
  }

  return new Promise((resolve, reject) => {
    let response: IHttpResponse<T>;
    fetch(request)
    .then(res => {
      response = res;
      return res.json();
    })
    .then(body => {
      if (response.ok) {
        response.parsedBody = body;
        resolve(response);
      } else {
        reject(body["error"]);
      }
    })
    .catch(err => {
      console.log('---------------');
      console.log(err);
      console.log('---------------');
      reject(err);
    });
  });
};
