import {FC, useState, useEffect} from "react";
import {useHistory, useParams} from 'react-router-dom';
import {http} from "../../http";
import {API_BASE_URL} from "Constants";
import { SubmitHandler, useForm } from "react-hook-form";
import HubsEditForm from "Components/Sponsor/HubsEditForm";

type HubsEditPageProps = {}

export type FormState = {
  name: string,
  state: string;
  city: string;
  address_line_1: string;
  address_line_2: string;
  postal_code: string;
  image: string,
  lat: number,
  lng: number,
}

interface HubsEditResponse {
}

interface State {
  key: number;
  name: string;
}

interface Hub {
  name: string,
  state: string;
  city: string;
  address_line_1: string;
  address_line_2: string;
  postal_code: string;
  image: string,
}

interface HubResponse {
  hub: Hub,
  states: State[],
}

export const HubsEditPage: FC<HubsEditPageProps> = (props) => {

  const [error, setError] = useState<string>("");

  const history = useHistory();

  const { id } = useParams<{ id: string }>();


  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
  });

  useEffect(() => {
    const getHub = async () => {
      try {
        const response = await http<HubResponse>(API_BASE_URL + `/sponsor/hubs/${id}/show`, "GET");
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        setValue("name", body.hub.name)
        setValue("state", body.hub.state)
        setValue("city", body.hub.city)
        setValue("address_line_1", body.hub.address_line_1)
        setValue("address_line_2", body.hub.address_line_2)
        setValue("postal_code", body.hub.postal_code)
        setValue("image", body.hub.image)
        setValue("lat", 0)
        setValue("lng", 0)
      } catch (ex: any) {
        setError(ex.toString());
      } finally {
      }
    };
    getHub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id])

  const handleFormSubmit: SubmitHandler<FormState> = async (data) => {
    try {
      await getGeocode(data.state + ' ' + data.city + ' ' + data.address_line_1)
      await http<HubsEditResponse>(API_BASE_URL + `/sponsor/hubs/${id}/update`, "POST",{
        "name": data.name,
        "state": data.state,
        "city": data.city,
        "address_line_1": data.address_line_1,
        "address_line_2": data.address_line_2,
        "postal_code": data.postal_code,
        "image": data.image,
        "lat": getValues("lat"),
        "lng": getValues("lng"),
      });
      history.push("/sponsor/hubs");
    } catch (ex: any) {
      if (ex === "ZERO_RESULTS") {
      } else {
        ex.forEach((e: string) => {
          if (e === "lng" || e === "lat") {
            setError("番地の詳細を入力してください。")
          } else {
            setError(ex.toString());
          }
        })
      }
    } finally {
    }
  };

  const getGeocode = (address: string) =>  {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          if (results != null && results[0]) {
            const location = results[0].geometry.location;
            setValue("lat", location.lat())
            setValue("lng", location.lng())
            resolve(status)
          }
        } else {
          reject(status)
        }
      });
    })
  }

  return (
    <HubsEditForm
    handleFormSubmit={handleFormSubmit}
    handleSubmit={handleSubmit}
    register={register}
    watch={watch}
    errors={errors}
    setValue={setValue}
    getValues={getValues}
    setError={setError}
    error={error}
    />
  )
}