import * as THREE from "three";
import  { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { useFrame } from "@react-three/fiber";

type ActionName =
  | "body_popup"
  | "left_feather_patapata"
  | "right_feather_patapata";
  interface GLTFAction extends THREE.AnimationClip {
    name: ActionName;
  }

type GLTFResult = GLTF & {
  nodes: {
    Sphere: THREE.Mesh;
    Sphere_1: THREE.Mesh;
    eyeball: THREE.Mesh;
    legs: THREE.Mesh;
    left_feather: THREE.Mesh;
    scarf: THREE.Mesh;
    antenna: THREE.Mesh;
    right_feather: THREE.Mesh;
  };
  materials: {
    body_material: THREE.MeshStandardMaterial;
    black_material: THREE.MeshStandardMaterial;
    feather_material: THREE.MeshStandardMaterial;
    white_material: THREE.MeshStandardMaterial;
  };
  animations: GLTFAction[];
};

export default function Model(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<THREE.Group>();
  const { nodes, materials, animations } = useGLTF("/static/bee.glb") as unknown as GLTFResult;
  const { actions } = useAnimations<GLTFAction>(animations, group);
  useEffect(() => {
    actions.body_popup!.setLoop(THREE.LoopOnce, 0)
    actions.body_popup!.clampWhenFinished = true;
    actions.body_popup!.play()
    actions.right_feather_patapata!.play()
    actions.left_feather_patapata!.play()
  })

  useFrame((state, delta) => {
    const elapsed = state.clock.getElapsedTime()
    group.current!.rotation.y -= 0.005;
    group.current!.position.y = (1 + Math.sin(elapsed / 1.5)) / 1.4;
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="body"
          position={[0, 0, -0.2]}
          rotation={[1.2, 0, 0]}
          scale={0}
        >
          <mesh
            name="Sphere"
            castShadow
            receiveShadow
            geometry={nodes.Sphere.geometry}
            material={materials.body_material}
          />
          <mesh
            name="Sphere_1"
            castShadow
            receiveShadow
            geometry={nodes.Sphere_1.geometry}
            material={nodes.Sphere_1.material}
          />
        </group>
        <mesh
          name="eyeball"
          castShadow
          receiveShadow
          geometry={nodes.eyeball.geometry}
          material={nodes.eyeball.material}
          position={[0, -0.08, 1.51]}
          rotation={[-Math.PI, 0.1, 0]}
          scale={0}
        />
        <mesh
          name="legs"
          castShadow
          receiveShadow
          geometry={nodes.legs.geometry}
          material={nodes.legs.material}
          position={[0, -0.96, -0.3]}
          rotation={[Math.PI / 2, -1.57, 0]}
          scale={0}
        />
        <mesh
          name="left_feather"
          castShadow
          receiveShadow
          geometry={nodes.left_feather.geometry}
          material={nodes.left_feather.material}
          position={[-0.6, 0.87, 0.08]}
          rotation={[0, 0, -1.1]}
          scale={0}
        />
        <mesh
          name="scarf"
          castShadow
          receiveShadow
          geometry={nodes.scarf.geometry}
          material={materials.white_material}
          position={[0, 0, -0.2]}
          rotation={[0.87, -0.98, -0.61]}
          scale={0}
        />
        <mesh
          name="antenna"
          castShadow
          receiveShadow
          geometry={nodes.antenna.geometry}
          material={nodes.antenna.material}
          position={[0, -0.08, 1.3]}
          rotation={[-Math.PI, 0.1, 0]}
          scale={0}
        />
        <mesh
          name="right_feather"
          castShadow
          receiveShadow
          geometry={nodes.right_feather.geometry}
          material={nodes.right_feather.material}
          position={[0.6, 0.87, 0.08]}
          rotation={[0, 0, -1.1]}
          scale={0}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/static/bee.glb");
