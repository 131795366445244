import {FC, useState, useEffect} from "react";
import Typography from '@mui/material/Typography';
import {Container, Button} from "@mui/material";
import {http} from "../../http";
import {API_BASE_URL} from "../../Constants";
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {LOCALSTORAGE_TOKEN_SPONSOR} from "../../Constants";
import ErrorBanner from "Components/ErrorBanner"

type SponsorsPageProps = {}

interface Sponsor {
  id: number,
  name: string,
  corporation: string,
  email: string,
  status: number,
}

interface SponsorsResponse {
  sponsors: Sponsor[],
}

interface SponsorsGetAuthResponse {
  token: string,
}

export const SponsorsPage: FC<SponsorsPageProps> = (props) => {

  const [error, setError] = useState<string>("");
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    margin: {
      marginTop: 50,
    }
  });

  const classesPage = useStyles();


  useEffect(() => {
    const getSponsors = async () => {
			try {
				const response = await http<SponsorsResponse>(API_BASE_URL + "/admin/sponsors", "GET");
				const body = response.parsedBody
				if (!body) {
					setError('response error')
					return
				}
				setSponsors(body.sponsors)
			} catch (ex: any) {
				setError(ex.toString());
			} finally {
			}
		};
    getSponsors()
  },[])

  const onChangeCheckBox = (id: number, e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const sponsorsPublish = async () => {
			try {
				const response = await http<SponsorsResponse>(API_BASE_URL + `/admin/sponsors/${id}/publish`, "POST", {
          "test": "test",
        });
				const body = response.parsedBody
				if (!body) {
					setError('response error')
					return
				}
				setSponsors(body.sponsors)
			} catch (ex: any) {
				setError(ex.toString());
			} finally {
			}
		};
    if (window.confirm("この操作を行いますか？")) {
      void sponsorsPublish()
    }
  }

  const loginAsSponsor = async (id: number, e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const response = await http<SponsorsGetAuthResponse>(API_BASE_URL + `/admin/sponsors/${id}/auth`, "GET");
      const body = response.parsedBody
      if (!body) {
        setError('response error')
        return
      }
      localStorage.setItem(LOCALSTORAGE_TOKEN_SPONSOR, body.token);
      window.open("/sponsor/hubs", '_blank');
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  }

  return (
      <Container component="main" maxWidth="xl">
        <div className="">
          <Typography component="h1" variant="h5">
            Sponsors
          </Typography>

          <ErrorBanner error={error}></ErrorBanner>

          <TableContainer component={Paper}>
            <Table className={classesPage.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>名前</TableCell>
                  <TableCell align="right">法人</TableCell>
                  <TableCell align="right">メールアドレス</TableCell>
                  <TableCell align="right">神機能</TableCell>
                  <TableCell align="right">公開/非公開</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sponsors.map((sponsor) => (
                  <TableRow key={sponsor.name}>
                    <TableCell component="th" scope="row">
                      {sponsor.name}
                    </TableCell>
                    <TableCell align="right">{sponsor.corporation}</TableCell>
                    <TableCell align="right">{sponsor.email}</TableCell>
                    <TableCell align="right">
                      <Button color="error" onClick={(e) => loginAsSponsor(sponsor.id, e)}>加盟店ページにログイン</Button>
                    </TableCell>
                    <TableCell align="right">
                      <Button color="success" onClick={(e) => onChangeCheckBox(sponsor.id, e)}>
                        {sponsor.status === 1 ? "非公開にする" : "公開にする"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
  )

};
