import {FC, useState, useEffect} from "react";
import {useParams} from 'react-router-dom';
import {http} from "../../../http";
import {API_BASE_URL} from "Constants";
import CouponCard from "Components/User/CouponCard";
import { Coupon } from "Types/App";
import Breadcrumbs from "Components/Breadcrumbs";
import { BreadcrumbPage } from "Types/App";
import { XMarkIcon, PencilSquareIcon } from '@heroicons/react/24/solid';
import ErrorBanner from "Components/ErrorBanner";
import Footer from "Components/Footer";

type CouponsPageProps = {}

interface CouponsRemoveResponse {
  coupons: Coupon[];
}

interface CouponsResponse {
  coupons: Coupon[],
}

export const Favorite: FC<CouponsPageProps> = (props) => {

  const [error, setError] = useState<string>("");
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  const { hub_id } = useParams<{ hub_id: string }>();

  useEffect(() => {
    const getCoupons = async () => {
      try {
        const response = await http<CouponsResponse>(API_BASE_URL + `/users/coupons/favorites/list`, "GET");
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        setCoupons(body.coupons)
      } catch (ex: any) {
        setError(ex.toString());
      } finally {
      }
    };
    getCoupons();
  },[hub_id])

  const handleRemove = async (id: number) => {
    try {
      const response = await http<CouponsRemoveResponse>(API_BASE_URL + `/users/coupons/favorites/remove`, "POST",{
        "coupon_id": id,
      });
      const body = response.parsedBody
      if (!body) {
        setError('response error')
        return
      }
      setCoupons(body.coupons)
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  const pages: BreadcrumbPage[] = [
    { name: "お気に入り", href: `/users/profile`, current: true },
  ]
  return (
    <>
      <div className="px-5 bg-white pb-10">
        <ErrorBanner error={error}></ErrorBanner>
          <Breadcrumbs pages={pages}></Breadcrumbs>
            <button
            onClick={() => setEditMode(!editMode)}
            >
              <div className="flex items-center bg-primary hover:bg-primary_darker text-white py-2 px-4 rounded-md">
                { !editMode ?
                  <span>編集</span> :
                  <span>完了</span>
                }
                <PencilSquareIcon className="text-white w-6 h-6"></PencilSquareIcon>
              </div>
            </button>
          <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-5">
            {coupons.map((coupon) => (
              <div key={coupon.id} className="relative mx-auto w-full sm:w-68 md:w-60 xl:w-72">
                <div className={`absolute w-6 h-6 rounded-full bg-primary z-10 -right-2 -top-2 text-center text-white shadow animate-ping ${editMode ? "block" : "hidden"}`}></div>
                <div onClick={() => handleRemove(coupon.id)} className={`absolute w-8 h-8 rounded-full bg-primary z-10 -right-3 -top-3 text-center text-white shadow justify-center items-center cursor-pointer ${editMode ? "flex" : "hidden"}`}>
                  <XMarkIcon className="text-white w-6 h-6"></XMarkIcon>
                </div>
                <CouponCard coupon={coupon} />
              </div>
            ))}
          </div>
      </div>
      <Footer></Footer>
    </>
  )

};