import {FC, useState, useEffect} from "react";
import {useHistory, useParams} from 'react-router-dom';
import {http} from "../../http";
import {API_BASE_URL} from "Constants";
import { SubmitHandler, useForm } from "react-hook-form";
import CouponsEditForm from './CouponsEditForm';

type CouponsEditPageProps = {}

export type FormState = {
  title: string,
  description: string;
  period_start: Date | null;
  period_end: Date | null;
  stock: string;
  image: string;
}

interface CouponsEditResponse {
}

interface Coupon {
  title: string,
  description: string,
  period_start: Date,
  period_end: Date,
  stock: string,
  image: string,
}

interface CouponResponse {
  coupon: Coupon,
}

export const CouponsEditPage: FC<CouponsEditPageProps> = (props) => {
  const [error, setError] = useState<string>("");
  const history = useHistory();

  const { hub_id, id } = useParams<{ hub_id: string, id: string }>();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
  });

  useEffect(() => {
    const getCoupon = async () => {
      try {
        const response = await http<CouponResponse>(API_BASE_URL + `/sponsor/hubs/${hub_id}/coupons/${id}/show`, "GET");
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        setValue("title", body.coupon.title);
        setValue("description", body.coupon.description);
        setValue("period_start", body.coupon.period_start);
        setValue("period_end", body.coupon.period_end);
        setValue("stock", String(body.coupon.stock));
        setValue("image", body.coupon.image);
      } catch (ex: any) {
        setError(ex.toString());
      } finally {
      }
    };
    getCoupon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hub_id, id])

  const handleFormSubmit: SubmitHandler<FormState> = async (data) => {
    try {
      const response = await http<CouponsEditResponse>(API_BASE_URL + `/sponsor/hubs/${hub_id}/coupons/${id}/update`, "POST",{
        "title": data.title,
        "description": data.description,
        "start": data.period_start,
        "end": data.period_end,
        "stock": data.stock,
        "image": data.image,
      });
      console.log("Response ", response.parsedBody)
      history.push(`/sponsor/hubs/${hub_id}/coupons`);
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  return (
    <CouponsEditForm
    handleFormSubmit={handleFormSubmit}
    handleSubmit={handleSubmit}
    register={register}
    errors={errors}
    getValues={getValues}
    setValue={setValue}
    watch={watch}
    error={error}
    />
  )

};
