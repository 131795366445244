import { FC, useState, useEffect, useRef } from "react";
import { http } from "../../http";
import { API_BASE_URL, GOOGLE_MAPS_API_KEY } from "Constants";
import {
  LoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  Autocomplete,
} from "@react-google-maps/api";
import CouponCard from "Components/User/CouponCard";
import { Coupon, Hub, FormState, HubsResponse } from "Types/App";
import HeroArea from "Components/User/HeroArea";
import Head from "Components/Head";
import Footer from "Components/Footer";
import ErrorBanner from "Components/ErrorBanner";
import { Link } from "react-router-dom";

const containerStyle = {
  width: "100%",
  height: "70vh",
};

let mapCenter = {
  lat: 0,
  lng: 0,
};

const radiusFromZoomLevel = (zoom: number) => {
  if (zoom <= 8) {
    return 150_000;
  }

  return 150_000 / Math.pow(2, zoom - 8);
};

type IndexPageProps = {};

export const IndexPage: FC<IndexPageProps> = (props) => {
  const [error, setError] = useState<string>("");
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [mapHubs, setMapHubs] = useState<Hub[]>([]);
  const [infoWindowID, setInfoWindowID] = useState(0);
  const [autocomp, setAutocomp] = useState<google.maps.places.Autocomplete>();

  const mapRef = useRef<any>(null);

  const [form, setForm] = useState<FormState>({
    lat: 0,
    lng: 0,
    radius: 0.5,
  });
  type Libraries = (
    | "drawing"
    | "geometry"
    | "localContext"
    | "places"
    | "visualization"
  )[];
  const [libraries] = useState<Libraries>(["places"]);

  useEffect(() => {
    getTop();
    // eslint-disable-next-line
  }, []);

  const getTop = async () => {
    try {
      const response = await http<HubsResponse>(
        API_BASE_URL + `/public/top`,
        "GET"
      );
      const body = response.parsedBody;
      if (!body) {
        setError("response error");
        return;
      }
      setCoupons(body.coupons);
      mapCenter = {
        lat: body.lat,
        lng: body.lng,
      };
      getLocations();
    } catch (ex) {
      if (ex instanceof Error) {
        setError(ex.toString());
      } else {
        setError("unknown error");
      }
    } finally {
    }
  };

  const getLocations = async () => {
    try {
      setForm({
        lat: 0,
        lng: 0,
        radius: 5,
      });
      if (!mapRef.current) {
        form.lat = mapCenter.lat;
        form.lng = mapCenter.lng;
      } else {
        if (mapRef.current.zoom) {
          form.radius = radiusFromZoomLevel(mapRef.current.zoom);
        }

        form.lat = mapRef.current.center.lat();
        form.lng = mapRef.current.center.lng();
      }
      const response = await http<HubsResponse>(
        API_BASE_URL + `/public/hubs`,
        "POST",
        {
          lat: form.lat,
          lng: form.lng,
          radius: form.radius,
        }
      );
      const body = response.parsedBody;
      if (!body) {
        setError("response error");
        return;
      }
      setMapHubs(body.hubs);
      setInfoWindowID(0);
    } catch (ex) {
      if (ex instanceof Error) {
        setError(ex.toString());
      } else {
        setError("unknown error");
      }
    } finally {
    }
  };

  const getLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position: any) => {
    form.lat = position.coords.latitude;
    form.lng = position.coords.longitude;
    mapRef.current.setCenter({ lat: form.lat, lng: form.lng });
    getLocations();
  };

  const handleLoad = (map: any) => {
    mapRef.current = map;
  };

  let markers;
  if (mapHubs !== null) {
    markers = mapHubs.map((hub, i) => {
      const position = {
        lat: hub.latitude,
        lng: hub.longitude,
      };
      const index = i + 1;
      const coupons = hub.coupons;
      return (
        <Marker
          key={index}
          position={position}
          onClick={() => {
            setInfoWindowID(index);
          }}
        >
          {infoWindowID === index && (
            <InfoWindow position={position}>
              <div className="w-40">
                {coupons.map((coupon, i) => {
                  return (
                    <div className="mb-2">
                      <img
                        alt={coupon.title}
                        src={coupon.image}
                        className="w-full"
                      />
                      <h4 className="mt-2">{coupon.title}</h4>
                      <p>
                        {hub.state}
                        {hub.city}
                        {hub.address_line_1} {hub.address_line_2}
                      </p>
                      <div>
                        <Link
                          to={`/public/coupons/${coupon.id}`}
                          className="block text-center w-full mt-2 bg-transparent text-image_color font-semibold py-2 px-4 border border-image_color rounded"
                        >
                          クーポンをみる
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </InfoWindow>
          )}
        </Marker>
      );
    });
  }

  return (
    <>
      <Head title="Boomin'(ブーミン)｜ローカルクーポンでお得に寄り道しよう" />
      <main>
        <HeroArea getLocation={getLocation}></HeroArea>
        <ErrorBanner error={error}></ErrorBanner>
        <div className="bg-white w-full mx-auto py-8">
          <div className="pl-4">
            <h2 className="text-lg font-bold leading-tight text-gray-900">
              原宿・表参道エリア
            </h2>
            <ul className="overflow-x-auto whitespace-nowrap mt-4">
              {coupons.map((coupon) => {
                // 一旦ハードコード
                if (
                  coupon.id === 8 ||
                  coupon.id === 9 ||
                  coupon.id === 10 ||
                  coupon.id === 11 ||
                  coupon.id === 12 ||
                  coupon.id === 13
                ) {
                  return (
                    <li className="inline-block mr-4 w-60" key={coupon.id}>
                      <CouponCard coupon={coupon} />
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
            <h2 className="text-lg font-bold leading-tight text-gray-900 mt-8">
              おすすめクーポン
            </h2>
            <ul className="overflow-x-auto whitespace-nowrap mt-4">
              {coupons.map((coupon) => (
                <li className="inline-block mr-4 w-60" key={coupon.id}>
                  <CouponCard coupon={coupon} />
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-between px-4">
            <h2
              id="map"
              className="text-lg font-bold leading-tight text-gray-900 mt-8"
            >
              マップから探す
            </h2>
            <button onClick={() => getLocation()} className="mt-8">
              現在地を取得する
            </button>
          </div>

          <div className="pt-4 relative">
            <LoadScript
              googleMapsApiKey={GOOGLE_MAPS_API_KEY}
              libraries={libraries}
              region="JP"
              language="ja"
            >
              <GoogleMap
                onLoad={handleLoad}
                mapContainerStyle={containerStyle}
                center={mapCenter}
                zoom={17}
                onDragEnd={getLocations}
                onZoomChanged={getLocations}
                options={{
                  fullscreenControl: false,
                  mapTypeControl: false,
                }}
              >
                <Autocomplete
                  onLoad={(e) => setAutocomp(e)}
                  onPlaceChanged={() => {
                    if (autocomp) {
                      const place = autocomp.getPlace();
                      const lat = place.geometry?.location?.lat();
                      const lng = place.geometry?.location?.lng();
                      if (lat && lng) {
                        mapRef.current.setCenter({ lat: lat, lng: lng });
                        getLocations();
                      }
                    }
                  }}
                >
                  <div className="px-4 top-4 absolute w-full">
                    <input
                      type="text"
                      placeholder="探したいエリア名を入力してください"
                      className="py-6 w-full border border-gray-100 rounded-md"
                      style={{
                        boxSizing: `border-box`,
                        height: `40px`,
                      }}
                    />
                  </div>
                </Autocomplete>

                {markers}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};
