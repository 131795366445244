import {FC, useEffect, useState} from "react";
import Footer from "Components/Footer"
import ProfileEditForm from "Components/User/ProfileEditForms/ProfileEditForm";
import { API_BASE_URL } from "Constants";
import { http } from "../../http";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { DEFAULT_USER_IMAGE } from '../../Constants';

type ProfileEditPageProps = {}
export interface UserProfile {
  email: string,
  phone: string,
  user_name: string,
  first_name: string,
  last_name: string,
  birth_date: string,
  gender: string,
  state: string;
  city: string;
  address_line_1: string;
  address_line_2: string;
  postal_code: string;
  profile_image: string,
}

interface State {
  key: number;
  name: string;
}

interface HubResponse {
  user: UserProfile,
  states: State[],
}

export const ProfileEditPage: FC<ProfileEditPageProps> = (props) => {

  const [error, setError] = useState<string>("");

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UserProfile>({
    mode: "onChange",
  });
  const gender = watch("gender")

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await http<HubResponse>(API_BASE_URL + `/users/profile`, "GET");
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        let birth_date = body.user.birth_date;
        if (birth_date === "0001-01-01T00:00:00Z") {
          birth_date = new Date().toISOString().split('T')[0]
        }
        setValue("email", body.user.email)
        setValue("phone", body.user.phone)
        setValue("user_name", body.user.user_name)
        setValue("first_name", body.user.first_name)
        setValue("last_name", body.user.last_name)
        setValue("birth_date", birth_date)
        setValue("gender", body.user.gender)
        setValue("state", body.user.state)
        setValue("city", body.user.city)
        setValue("address_line_1", body.user.address_line_1)
        setValue("address_line_2", body.user.address_line_2)
        setValue("postal_code", body.user.postal_code)
        setValue("profile_image", body.user.profile_image)
      } catch (ex: any) {
        setError(ex.toString());
      } finally {
      }
    };
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  const handleFormSubmit: SubmitHandler<UserProfile> = async (data) => {
    try {
      if (data.profile_image.startsWith("http")) {
        data.profile_image = DEFAULT_USER_IMAGE
      }
      if (data.birth_date.indexOf("Z") === -1) {
        data.birth_date += "T00:00:00Z"
      }
      await http(API_BASE_URL + `/users/profile/update`, "POST", {
        "phone": data.phone,
        "user_name": data.user_name,
        "first_name": data.first_name,
        "last_name": data.last_name,
        "birth_date": data.birth_date,
        "gender": data.gender,
        "state": data.state,
        "city": data.city,
        "address_line_1": data.address_line_1,
        "address_line_2": data.address_line_2,
        "postal_code": data.postal_code,
        "profile_image": data.profile_image,
      });
      const nextPage = localStorage.getItem('nextPage')
      if (nextPage) {
        localStorage.removeItem('nextPage');
        history.push(nextPage);
      } else {
        history.push("/");
      }
    } catch (ex: any) {
      console.log(ex.toString())
      setError(ex.toString())
    } finally {
    }
  };

  return (
    <>
    <div className="space-y-6">
    <ProfileEditForm
    handleFormSubmit={handleFormSubmit}
    handleSubmit={handleSubmit}
    register={register}
    errors={errors}
    setValue={setValue}
    getValues={getValues}
    gender={gender}
    watch={watch}
    setError={setError}
    error={error}
    />
    </div>
    <Footer></Footer>
    </>
  )
};
