import {FC, useState} from "react";
import {useHistory} from 'react-router-dom';
import {API_BASE_URL, LOCALSTORAGE_TOKEN_SPONSOR} from "../../../Constants";
import {http} from "../../../http";
import {useGlobalState} from "state-pool";
import { SubmitHandler, useForm } from "react-hook-form";
import RegisterForm from "./RegisterForm";

type RegisterPageProps = {}

export type FormState = {
  name: string,
  corporation: string,
  email: string;
  password: string;
  password2: string;
}

interface RegisterResponse {
  token: string
}

export const RegisterPage: FC<RegisterPageProps> = (props) => {
  const [error, setError] = useState<string>("");
  const [loggedIn, setLoggedIn] = useGlobalState("loggedInSponsor");
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
  });

  const handleFormSubmit: SubmitHandler<FormState> = async (data) => {
    try {
      if (data.password !== data.password2) {
        setError('パスワードが一致しません');
        return
      }
      const response = await http<RegisterResponse>(API_BASE_URL + "/public/sponsor/signup", "POST", {
        "name": data.name,
        "corporation": data.corporation,
        "email": data.email,
        "password": data.password,
        "password2": data.password2
      });
      console.log("Response ", response.parsedBody)
      const body = response.parsedBody
      if (!body) {
        setError("response error")
        return
      }
      localStorage.setItem(LOCALSTORAGE_TOKEN_SPONSOR, body.token)
      setLoggedIn(true);
      console.log(loggedIn);
      history.push("/sponsor/hubs");
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  return (
    <RegisterForm
    handleFormSubmit={handleFormSubmit}
    handleSubmit={handleSubmit}
    register={register}
    errors={errors}
    error={error}
    />
  )

};
