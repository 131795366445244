type TableProps = {
  headers: string[];
  data: React.ReactNode[][];
};

const Table: React.FC<TableProps> = ({ headers, data }) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                {headers.map((header) => (
                  <th
                    key={header}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <>
                  {data.map((rowData) => (
                    <tr>
                      {rowData.map((cellData) => (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {cellData}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
/**
 *   return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                {headers.map((header) => (
                  <th
                    key={header}
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  {data.map((d_1) => (
                    <tr>
                      {d_1.map((d_2) => (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {d_2}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  );
};
*/