import {FC, useState, useEffect} from "react";
import Typography from '@mui/material/Typography';
import {http} from "../../http";
import {API_BASE_URL} from "Constants";
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ErrorBanner from "Components/ErrorBanner"

type CouponUsedPageProps = {}

interface CouponUsedResponse {
  result: any[],
}

export const CouponUsedPage: FC<CouponUsedPageProps> = (props) => {

  const [error, setError] = useState<string>("");
  const [result, setResult] = useState<any[]>([]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    margin: {
      marginTop: 50,
    }
  });

  const classesPage = useStyles();

  useEffect(() => {
    getHubs()
  },[])

  const getHubs = async () => {
    try {
      const response = await http<CouponUsedResponse>(API_BASE_URL + "/sponsor/hubs/coupon/used", "GET");
      const body = response.parsedBody
      if (!body) {
        setError('response error')
        return
      }
      setResult(body.result)
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  return (
    <div className="container mx-auto py-10 pt-20 sm:pt-10">
      <div className="">
        <Typography component="h1" variant="h5">
          クーポン使用一覧
        </Typography>

        <ErrorBanner error={error}></ErrorBanner>

        <TableContainer component={Paper}>
          <Table className={classesPage.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ユーザー</TableCell>
                <TableCell>クーポン</TableCell>
                <TableCell>店舗QRコード使用</TableCell>
                <TableCell>時刻</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.map((r) => (
                <TableRow key={r.name}>
                  <TableCell>
                    <img alt="" src={r.profile_image} style={{ width: '50px' }} />
                  </TableCell>
                  <TableCell>
                    <img alt="" src={r.image} style={{ width: '50px' }} />
                    {r.title}
                  </TableCell>
                  <TableCell>
                    {r.from_qr_code ? "はい" : "いいえ"}
                  </TableCell>
                  <TableCell>
                    {r.created}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )

};
