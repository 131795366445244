import { IdentificationIcon } from "@heroicons/react/24/outline"
import ErrorBanner from "Components/ErrorBanner"
import { Input } from "Components/Input"
import { UseFormHandleSubmit, UseFormRegister } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormState } from "./RegisterPage";

const RegisterForm = ({handleFormSubmit, handleSubmit, register, errors, error}: {handleFormSubmit: any, handleSubmit: UseFormHandleSubmit<FormState>,
     register: UseFormRegister<FormState>, errors: any, error: string}) => {
  return (
    <>
      <div className=" bg-white h-full px-4 py-5 sm:p-6">
        <h1 className="mt-6 mb-8 text-3xl text-center">加盟店新規登録</h1>
        <ErrorBanner error={error}></ErrorBanner>
        <form method="POST" onSubmit={handleSubmit(handleFormSubmit)}>
          {/* 氏名 */}
          <div className="grid grid-cols-6 gap-7">
            <div className="col-span-6 sm:col-start-2 sm:col-span-4">
              <Input
                label="氏名"
                name="name"
                register={register}
                required="氏名を入力してください"
                maxLength={{ value: 20, message: "氏名が長すぎます。" }}
                minLength={{ value: 2, message: "氏名が短すぎます。" }}
                pattern={{ value: /.+/, message: "氏名の形式が不正です。" }}
                password={false}
                autoComplete="name"
                placeholder="田中 太郎"
                icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                errors={errors.name}
              />
            </div>

            {/* 法人名 */}
            <div className="col-span-6 sm:col-start-2 sm:col-span-4">
              <Input
                label="法人名"
                name="corporation"
                register={register}
                required="法人名を入力してください"
                maxLength={{ value: 25, message: "法人名が長すぎます。" }}
                minLength={{ value: 1, message: "法人名が短すぎます。" }}
                pattern={{ value: /.+/, message: "法人名の形式が不正です。" }}
                password={false}
                autoComplete="name"
                placeholder="株式会社Rebase"
                icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                errors={errors.corporation}
              />
            </div>

            {/* メールアドレス */}
              <div className="col-span-6 sm:col-start-2 sm:col-span-4">
                <Input
                  label="メールアドレス"
                  name="email"
                  register={register}
                  required="メールアドレスを入力してください"
                  maxLength={{ value: 2048, message: "メールアドレスが長すぎます。" }}
                  minLength={{ value: 6, message: "メールアドレスが短すぎます。" }}
                  pattern={{ value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "メールアドレスの形式が不正です。" }}
                  password={false}
                  autoComplete="email"
                  placeholder=""
                  icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.email}
                />
              </div>

              {/* パスワード */}
              <div className="col-span-6 sm:col-start-2 sm:col-span-4">
                <Input
                  label="パスワード"
                  name="password"
                  register={register}
                  required="パスワードを入力してください"
                  maxLength={{ value: 100, message: "パスワードが長すぎます。" }}
                  minLength={{ value: 4, message: "パスワードが短すぎます。" }}
                  pattern={{ value: /.+/, message: "パスワードの形式が不正です。" }}
                  password={true}
                  autoComplete="current-password"
                  placeholder=""
                  icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.password}
                />
              </div>
              {/* パスワード2 */}
              <div className="col-span-6 sm:col-start-2 sm:col-span-4">
                <Input
                  label="パスワード再入力"
                  name="password2"
                  register={register}
                  required="パスワード2を入力してください"
                  maxLength={{ value: 100, message: "パスワード2が長すぎます。" }}
                  minLength={{ value: 4, message: "パスワード2が短すぎます。" }}
                  pattern={{ value: /.+/, message: "パスワード2の形式が不正です。" }}
                  password={true}
                  autoComplete="current-password"
                  placeholder=""
                  icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.password2}
                />
              </div>
          </div>
          <div className="mt-8 text-center">
            <p>
              <a href="https://storage.instabase.jp/boomin_term.pdf" rel="noopener noreferrer nofollow">利用規約</a>
            </p>

            <button
              type="submit"
              className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              利用規約に同意して登録
            </button>
          </div>
          <div className="mt-8 text-center">
            アカウントをお持ちの方はこちら
            <Link to="/sponsor/login" className="text-blue-500 whitespace-pre"> ログイン</Link>
          </div>
        </form>
      </div>
    </>
  )
}
export default RegisterForm;