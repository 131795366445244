import {FC, useState, useEffect} from "react";
import {Container} from "@mui/material";
import {http} from "../../http";
import {API_BASE_URL, LOCALSTORAGE_TOKEN_ADMIN} from "Constants";
import { useLocation, useHistory } from 'react-router-dom';
import {useGlobalState} from "state-pool";
import ErrorBanner from "Components/ErrorBanner"

interface QueryString {
  [key: string]: string;
}

type CallbackPageProps = {}

interface CallbackResponse {
  token: string,
}

export const OAuthCallbackGooglePage: FC<CallbackPageProps> = (props) => {

  const [error, setError] = useState<string>("");

  const [loggedIn, setLoggedIn] = useGlobalState("loggedInAdmin");
  console.log("loggedIn", loggedIn);

  const history = useHistory();

  const search = useLocation().search;
  const query = search
    .slice(1)
    .split('&')
    .map((str) => [str.split('=')[0], str.split('=')[1]])
    .reduce((acc, a) => {
      acc[a[0]] = a[1];
      return acc;
    }, {} as QueryString);

  useEffect(() => {
    const callback = async () => {
      try {
        const code = query['code'];
        const response = await http<CallbackResponse>(API_BASE_URL + "/public/oauth/callback/google?code=" + code, "GET");
        console.log("Response ", response.parsedBody)
        const body = response.parsedBody
        if (!body) {
          setError('response error')
          return
        }
        localStorage.setItem(LOCALSTORAGE_TOKEN_ADMIN, body.token);
        setLoggedIn(true);
        history.push("/admin")
      } catch (ex: any) {
        setError(ex.toString());
      } finally {
      }
    };
    callback()
  },[history, query, setLoggedIn])

  return (
      <Container component="main" maxWidth="xl">
        <ErrorBanner error={error}></ErrorBanner>
      </Container>
  )

};
