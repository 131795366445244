import { MapPinIcon, IdentificationIcon } from "@heroicons/react/24/solid"
import ErrorBanner from "Components/ErrorBanner"
import { UseFormGetValues, UseFormHandleSubmit, UseFormRegister, UseFormSetValue } from "react-hook-form"
import { FormState } from "../../Pages/Sponsor/HubsAddPage"
import { Input } from "Components/Input"
import { LoadScript } from "@react-google-maps/api"
import { useHistory } from "react-router-dom"
import { GOOGLE_MAPS_API_KEY } from "Constants"
import { ImageInput } from "Components/ImageInput"
import { InputPostalCode } from "Components/PostalCodeInput"

const HubsAddForm = ({handleFormSubmit, handleSubmit, register, watch, errors, setValue, getValues,
 setError, error}: {handleFormSubmit: any, handleSubmit: UseFormHandleSubmit<FormState>,
   register: UseFormRegister<FormState>, watch: any, errors: any, setValue: UseFormSetValue<FormState>, getValues: UseFormGetValues<FormState>,
    setError: any, error: string}) => {
  const history = useHistory()
  return(
    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
      <ErrorBanner error={error}></ErrorBanner>
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">基本情報</h3>
          <p className="mt-1 text-sm text-gray-500">追加したい店舗の情報を入力してください。</p>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form method="POST" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="grid grid-cols-6 gap-6">

              {/* 店舗名 */}
              <div className="col-span-6 sm:col-span-6">
                <Input
                  label="店舗名"
                  name="name"
                  register={register}
                  required="店舗名を入力してください"
                  maxLength={{ value: 20, message: "店舗名が長すぎます。" }}
                  minLength={{ value: 1, message: "店舗名が短すぎます。" }}
                  pattern={{ value: /.+/, message: "店舗名の形式が不正です。" }}
                  password={false}
                  autoComplete="username"
                  placeholder=""
                  icon={<IdentificationIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.name}
                />
              </div>

              <ImageInput label="店舗画像" required={false} name="image" watch={watch} aspectRatio={1/1} getValues={getValues} setError setValue={setValue}></ImageInput>

              {/* 郵便番号 */}
              <div className="col-span-6 sm:col-span-4">
                <InputPostalCode
                  register={register}
                  required="郵便番号を入力してください"
                  errors={errors.postal_code}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                />
              </div>

              {/* 都道府県 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="都道府県"
                  name="state"
                  register={register}
                  required="都道府県名を入力してください"
                  maxLength={{ value: 6, message: "都道府県名が長すぎます" }}
                  minLength={{ value: 1, message: "都道府県名が短すぎます" }}
                  pattern={{ value: /^[ぁ-んァ-ヶー一-龠]+$/, message: '都道府県名の形式が不正です。' }}
                  password={false}
                  autoComplete="state"
                  placeholder="例) 東京都"
                  icon={<MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.state}
                />
              </div>

              {/* 市区町村名 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="市区町村名"
                  name="city"
                  register={register}
                  required="市区町村名を入力してください"
                  maxLength={{ value: 11, message: "市区町村名が長すぎます" }}
                  minLength={{ value: 1, message: "市区町村名が短すぎます" }}
                  pattern={{ value: /^[ぁ-んァ-ヶー一-龠]+$/, message: '市区町村名の形式が不正です。' }}
                  password={false}
                  autoComplete="city"
                  placeholder="例) 渋谷区"
                  icon={<MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.city}
                />
              </div>

              {/* 番地 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="番地"
                  name="address_line_1"
                  register={register}
                  required="番地を入力してください"
                  maxLength={{ value: 20, message: "番地が長すぎます" }}
                  minLength={{ value: 1, message: "番地が短すぎます" }}
                  pattern={{ value: /.+/, message: '番地の形式が不正です。' }}
                  password={false}
                  autoComplete="address_level3"
                  placeholder="例) 恵比寿西2丁目20-17"
                  icon={<MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.addressLine1}
                />
              </div>

              {/* 番号 */}
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label="番号"
                  name="address_line_2"
                  register={register}
                  required={false}
                  maxLength={{ value: 5, message: "番号が長すぎます" }}
                  minLength={{ value: 1, message: "番号が短すぎます" }}
                  pattern={{ value: /.+/, message: '番号の形式が不正です。' }}
                  password={false}
                  autoComplete="address_level4"
                  placeholder="例) 202"
                  icon={<MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  errors={errors.addressLine2}
                />
              </div>
            </div>
            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-3">
            <button
              type="button"
              onClick={() => history.push("/sponsor/hubs")}
              className=" order-last sm:order-1 col-span-2 sm:col-span-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              キャンセル
            </button>
            <button
              type="submit"
              className="sm:order-2 col-span-2 sm:col-span-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              作成する
            </button>
            </div>
          </form>
        </div>
        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}></LoadScript>
      </div>
    </div>

  )
}

export default HubsAddForm;