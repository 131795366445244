import {FC, useState} from "react";
import {useHistory} from 'react-router-dom';
import {http} from "../../../http";
import {API_BASE_URL} from "../../../Constants";
import {LOCALSTORAGE_TOKEN_SPONSOR} from "../../../Constants";
import {useGlobalState} from "state-pool";
import { SubmitHandler, useForm } from "react-hook-form";
import LoginForm from "./LoginForm";

type LoginPageProps = {}

export type FormState = {
  email: string;
  password: string;
}

interface LoginResponse {
  token: string;
}

export const LoginPage: FC<LoginPageProps> = (props) => {
  const [error, setError] = useState<string>("");
  const history = useHistory();

  const [, setLoggedIn] = useGlobalState("loggedInSponsor");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
  });

  const handleFormSubmit: SubmitHandler<FormState> = async (data) => {
    try {
      const response = await http<LoginResponse>(API_BASE_URL + "/public/sponsor/signin", "POST", {
        "email": data.email,
        "password": data.password
      });
      console.log("Response ", response.parsedBody)
      const body = response.parsedBody
      if (!body) {
        setError('response error');
        return
      }
      localStorage.setItem(LOCALSTORAGE_TOKEN_SPONSOR, body.token);
      setLoggedIn(true);
      history.push("/sponsor/hubs");
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  return (
    <LoginForm
      handleFormSubmit={handleFormSubmit}
      handleSubmit={handleSubmit}
      register={register}
      errors={errors}
      error={error}
    />
  )

};
