import {FC} from "react";
import {Link as RouterLink} from 'react-router-dom';
import {Link, Container} from "@mui/material";
import {useGlobalState} from "state-pool";

type IndexPageProps = {}

export const IndexPage: FC<IndexPageProps> = (props) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [loggedIn, setLoggedIn] = useGlobalState("loggedInAdmin");

  return (
    <Container component="main" maxWidth="xl">
      <h3>Admin Page</h3>
      {loggedIn && (
        <Link component={RouterLink} to="/admin/sponsors">
          Sponsors
        </Link>
      )}
    </Container>
  )
};
