const navigation = {
  main: [
    { name: 'クーポンを掲載する', href: '/sponsor' },
  ],
  sub: [
    { name: '運営会社', href: 'https://www.rebase.co.jp' },
    { name: '利用規約', href: 'https://storage.instabase.jp/boomin_term.pdf' },
    { name: 'プライバシーポリシー', href: 'https://www.instabase.jp/privacy' },
  ],
}

export default function Example() {
  return (
    <footer className="pt-6 pb-24 px-4 bg-gray-100">
      <p className="font-bold">ビーハッピー！Boomin'(ブーミン)</p>

      <nav className="mt-4" aria-label="Footer">
        {navigation.main.map((item) => (
          <div key={item.name} className="">
            <a href={item.href} className="">
              {item.name}
            </a>
          </div>
        ))}


        <div className="mt-4">
          <a href="https://www.instabase.jp" target="_blank" rel="noopener noreferrer">
            レンタルスペースを探すならインスタベース
          </a>
        </div>

        <div className="mt-4">
          {navigation.sub.map((item) => (
            <a href={item.href} className="mr-4" target="_blank" rel="noopener noreferrer">
              {item.name}
            </a>
          ))}
        </div>
      </nav>
      <div className="mt-4 text-center">&copy;{new Date().getFullYear()} 株式会社Rebase</div>
    </footer>
  )
}
