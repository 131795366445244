import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {LOCALSTORAGE_TOKEN_SPONSOR} from "../../Constants";
import { useGlobalState } from "state-pool";
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

export const Header = () => {
  const history = useHistory();

  const [loggedIn, setLoggedIn] = useGlobalState("loggedInSponsor");

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN_SPONSOR)
    setLoggedIn(token == null ? false : true);
  })

  const logout = () => {
    localStorage.removeItem(LOCALSTORAGE_TOKEN_SPONSOR);
    setLoggedIn(false);
    history.push("/sponsor/login");
  }

  const navigation = [
    { name: '店舗一覧', href: '/sponsor/hubs' },
    { name: 'クーポン使用状況', href: '/sponsor/hubs/coupon/used' },
    { name: 'Boomin\'トップ', href: '/' },

  ]

return (
  <Popover className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/sponsor" className="text-gray-700 font-bold">
              <span className="text-2xl">Boomin' </span>加盟店
            </a>
          </div>
          {!loggedIn ?
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a href="/sponsor/login" className="whitespace-nowrap text-base font-medium text-gray-700 hover:text-gray-400">
                ログイン
              </a>
              <a
                href="/sponsor/signup"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary"
              >
                新規登録
              </a>
            </div>
          :
            <>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary">
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group as="nav" className="hidden md:flex space-x-10">

                {
                  navigation.map((e) => (
                    <a href={e.href} className="text-base font-medium text-gray-700 hover:text-gray-400" key={e.href}>
                      {e.name}
                    </a>
                  ))
                }

              </Popover.Group>

              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <div
                  onClick={() => logout()}
                  className="ml-8 cursor-pointer whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary"
                >
                  ログアウト
                </div>
              </div>
            </>
          }
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                <a href="/sponsor" className="text-gray-500 font-bold">
                  Boomin' 加盟店
            </a>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>

              <div className="mt-6 mb-6">
                <nav className="grid gap-y-8">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
              {
              !loggedIn ?
              <>
                <a
                  href="/sponsor/signup"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary"
                >
                  新規登録
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  アカウントをお持ちですか？{' '}
                  <a href="/sponsor/login" className="text-primary hover:text-secondary">
                    ログイン
                  </a>
                </p>
              </>
                :
                <>
                <div
                  className="w-full flex items-center justify-center cursor-pointer px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary"
                  onClick={() => logout()}
                >
                  ログアウト
                </div>
                </>
            }
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
};