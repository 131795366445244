import { MapPinIcon } from "@heroicons/react/24/solid"
import { useState } from "react"
import { FieldError, UseFormGetValues, UseFormSetValue } from "react-hook-form"
import { autoCompleteFields } from "utils"

export const InputPostalCode = ({ register, required, errors, setValue, getValues, setError }:
  {
    register: any, required: boolean | string,
    errors: FieldError | undefined, setValue: UseFormSetValue<any>,
    getValues: UseFormGetValues<any>, setError: any
  }) => {
  const [postalValue, setPostalValue] = useState<string>("")
  return (
    <>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        郵便番号
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute top-2 left-0 pl-3 pointer-events-none">
          <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <div className="grid grid-cols-4">

          <div className="col-span-4 sm:col-span-3">
            <input
              className="h-9 px-2 focus:ring-secondary focus:border-secondary block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
              {
              ...register("postal_code", {
                required: required,
                maxLength: { value: 8, message: "郵便番号が長すぎます" },
                minLength: { value: 6, message: "郵便番号が短すぎます" },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "郵便番号の形式が不正です。",
                }
              })
              }
              onChange={(e) => { setPostalValue(e.target.value);}}
              id="postal_code"
              autoComplete={"postal-code"}
              placeholder={"例) 0993225"}
            />
          </div>
            <button onClick={(e) => { e.preventDefault(); autoCompleteFields(postalValue, setValue, setError); }} className="col-span-4 sm:col-span-1 bg-secondary mt-2 sm:ml-3 sm:mt-0 h-8 sm:h-full text-white w-full shadow-sm sm:text-sm border border-gray-300 rounded-md">
              住所の自動検索
            </button>
        </div>
      </div>
      {errors && <p className="text-red-600 text-xs">{errors.message}</p>}
  </>
  )
}