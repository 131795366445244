import {FC} from "react";
import {Route, Switch} from "react-router-dom";
import {OAuthCallbackInstabasePage} from "./Pages/Public/OAuthCallbackInstabasePage";
import {OAuthCallbackGooglePage} from "./Pages/Public/OAuthCallbackGooglePage";
import {HubPage} from "./Pages/Public/HubPage";
import {CouponPage} from "./Pages/Public/CouponPage";
import Dashboard from "./Pages/User/Dashboard";

type AppRoutesPublicProps = {}

export const AppRoutesPublic: FC<AppRoutesPublicProps> = (props) => {
  return (
    <Switch>
      <Route exact={true} path="/public" component={Dashboard}/>
      <Route path="/public/oauth/callback/google" component={OAuthCallbackGooglePage}/>
      <Route path="/public/oauth/callback/instabase" component={OAuthCallbackInstabasePage}/>
      <Route path="/public/hubs/:id" component={HubPage}/>
      <Route path="/public/coupons/:id" component={CouponPage}/>
    </Switch>
  )
};
