import {FC} from "react";
import {useLoginStyles} from "./Styles";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Container} from "@mui/material";
import {API_BASE_URL} from "../../Constants";

type LoginPageProps = {}

export const LoginPage: FC<LoginPageProps> = (props) => {
  const classes = useLoginStyles();
  const loginUrl = `${API_BASE_URL}/public/oauth/google`;
  return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon/>
          </Avatar>
          <Grid item xs={14}>
            <Button color="inherit" href={loginUrl}>Googleでログイン</Button>
          </Grid>
        </div>
      </Container>
  )

};
