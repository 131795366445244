import {FC} from "react";
import {Route, Switch, Redirect, RouteProps} from "react-router-dom";
import {IndexPage} from "./Pages/Sponsor/IndexPage";
import {LoginPage} from "./Pages/Sponsor/Auth/LoginPage";
import {RegisterPage} from "./Pages/Sponsor/Auth/RegisterPage";
import {LOCALSTORAGE_TOKEN_SPONSOR} from "./Constants";
import {HubsPage} from "./Pages/Sponsor/HubsPage";
import {HubsAddPage} from "./Pages/Sponsor/HubsAddPage";
import {HubsEditPage} from "./Pages/Sponsor/HubsEditPage";
import {CouponsPage} from "./Pages/Sponsor/CouponsPage";
import {CouponsAddPage} from "./Pages/Sponsor/CouponsAddPage";
import {CouponsEditPage} from "./Pages/Sponsor/CouponsEditPage";
import {useGlobalState} from "state-pool";
import {CouponUsedPage} from "./Pages/Sponsor/CouponUsedPage";

type AppRoutesProps = {}

export interface IPrivateRouteProps extends RouteProps {
  redirectPath: string
}

const PrivateRoute: FC<IPrivateRouteProps> = (props) => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN_SPONSOR);
  const isAuth = token ? true : false;
  return isAuth ? (
   <Route {...props} component={props.component} render={undefined} />
 ) : (
   <Redirect to={{pathname: props.redirectPath}} />
 )
}

export const AppRoutesSponsor: FC<AppRoutesProps> = (props) => {
  const [, setLoggedIn] = useGlobalState("loggedInSponsor");
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN_SPONSOR);
  const isAuth = token ? true : false;
  setLoggedIn(isAuth)
  return (
    <Switch>
      <Route exact={true} path="/sponsor" component={IndexPage}/>
      <Route path='/sponsor/login' component={LoginPage}/>
      <Route path='/sponsor/signup' component={RegisterPage}/>
      <PrivateRoute redirectPath="/sponsor/login" path='/sponsor/hubs/:hub_id/coupons/add' component={CouponsAddPage}/>
      <PrivateRoute redirectPath="/sponsor/login" path='/sponsor/hubs/:hub_id/coupons/:id/edit' component={CouponsEditPage}/>
      <PrivateRoute redirectPath="/sponsor/login" path='/sponsor/hubs/:hub_id/coupons' component={CouponsPage}/>
      <PrivateRoute redirectPath="/sponsor/login" path='/sponsor/hubs/add' component={HubsAddPage}/>
      <PrivateRoute redirectPath="/sponsor/login" path='/sponsor/hubs/:id/edit' component={HubsEditPage}/>
      <PrivateRoute redirectPath="/sponsor/login" path='/sponsor/hubs/coupon/used' component={CouponUsedPage}/>
      <PrivateRoute redirectPath="/sponsor/login" path='/sponsor/hubs' component={HubsPage}/>
    </Switch>
  )
};
