import { FormEvent, useState } from "react";
import AddToFavorite from 'Components/User/AddToFavorite'
import Breadcrumbs from "Components/Breadcrumbs"
import { Coupon, Hub } from "Types/App";
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import LoginModal from "Components/User/LoginModal"
import { ToLogin } from '../Common';

type Handle = (e: FormEvent) => Promise<void>

const UseButton = ({ handleUse, loggedIn, setIsShow, available}: { handleUse: Handle, loggedIn: boolean, setIsShow: React.Dispatch<React.SetStateAction<boolean>>, available: boolean}) => {
  if (!loggedIn) {
    return (
      <button
        type="submit"
        onClick={() => ToLogin(setIsShow)}
        className="w-full flex-1 bg-image_color rounded-md p-4 flex items-center justify-center font-bold font-lg text-white w-full"
      >
        ログインして使用する
      </button>
    )
  }
  if (!available) {
    return (
      <button
        type="button"
        className="w-full flex-1 bg-disabled_image_color rounded-md p-4 flex items-center justify-center font-bold font-lg text-white w-full"
      >
        使用済み
      </button>
    )
  }
  return (
    <button
      type="submit"
      onClick={handleUse}
      className="w-full flex-1 bg-image_color rounded-md p-4 flex items-center justify-center font-bold font-lg text-white w-full"
    >
      使用する
    </button>
  )
}

const CouponRedeemArea = ({ coupon, hub, handleUse, loggedIn, available }: { coupon: Coupon, hub: Hub, handleUse: Handle, loggedIn: boolean, available: boolean }) => {
  const [isShow, setIsShow] = useState(false);
  if (!coupon.id || !hub.id) {
    return null
  }

  const expiryDate = new Date(coupon.period_end);
  const expiry = `${expiryDate.getFullYear()}年${expiryDate.getMonth()+1}月${expiryDate.getDate()}日まで`

  const breadcrumbs = [
    { name: coupon.title, href: `/public/coupons${coupon.id}`, current: true },
  ]

  return (
    <div>
      <LoginModal isShow={isShow} setIsShow={setIsShow}></LoginModal>
      <main>
        <div className="px-4">
          <div className="relative">
            <img src={coupon.image} alt={coupon.title} className="w-full object-top object-contain rounded-lg" />

            <div className="absolute top-0 right-0 m-2">
              <AddToFavorite couponId={coupon.id} setIsShow={setIsShow} />
            </div>
          </div>

          <div className="mt-6">
            <h1 className="text-xl font-bold">{coupon.title}</h1>
          </div>

          <div className="mt-4">
            <table className="table-auto">
              <tbody>
                <tr>
                  <th className="pb-2 align-top" style={{ width: "34px" }}>住所</th>
                  <td className="px-4 pb-2">{hub.state}{hub.city}{hub.address_line_1} {hub.address_line_2}</td>
                </tr>
                <tr>
                  <th className="align-top" style={{ width: "34px" }}>期限</th>
                  <td className="px-4">{expiry}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-4 pt-4 border-t border-gray-100">
            <img className="inline object-cover w-6 h-6 mr-4 rounded-full -mt-1" src={hub.image} alt={hub.name} />
            <h2 className="inline font-bold">{hub.name}</h2>
          </div>

          <div className="mt-4 pt-4 border-t border-gray-100">
            <div className="bg-gray-100 rounded-md p-6">
              {loggedIn ? "レジでお支払いの前に「使用する」を押して、次の画面を提示してください。" : "クーポンを使用するにはアカウント作成またはログインが必要です。あらかじめ「ログインして使用する」から手続きを行なっておくと便利です。"}
            </div>
          </div>

          <div className="mt-4 whitespace-pre-wrap">
            {coupon.description}
          </div>

          <div className="mt-4 pt-4 border-t border-gray-100">
            <h3 className="text-lg font-bold">アクセス</h3>

            <div className="my-4">
              <iframe
                title="Google Maps"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCQvbBiefRM03FZojjOhLfCAJ6MDoEIgaQ&language=ja&q=${hub.latitude},${hub.longitude}&zoom=16`}
                className="h-96 w-full"
                loading="lazy"
              />
            </div>

            <div className="text-right">
              <a href={`https://maps.google.com/maps?q=${hub.latitude},${hub.longitude}&zoom=16`} rel="noopener noreferrer nofollow" target="_blank">Google マップで表示する</a>
              <ArrowTopRightOnSquareIcon className="w-5 h-5 inline-block" />
            </div>
          </div>
        </div>

        <div className="mt-4 border-t border-gray-100">
          <Breadcrumbs pages={breadcrumbs} />
        </div>

        <div className="fixed bottom-0 p-4 w-full" style={{ maxWidth: "640px" }}>
          <UseButton handleUse={handleUse} setIsShow={setIsShow} loggedIn={loggedIn} available={available} />
        </div>
      </main>
    </div>
  )
}

export default CouponRedeemArea;