import {FC, useState} from "react";
import {useHistory, useParams} from 'react-router-dom';
import {http} from "../../http";
import {API_BASE_URL} from "Constants";
import { SubmitHandler, useForm } from "react-hook-form";
import CouponsAddForm from "./CouponsAddForm";

type CouponsAddPageProps = {}

export type FormState = {
  title: string,
  description: string;
  period_start: Date | null;
  period_end: Date | null;
  stock: string;
  image: string;
}

interface CouponsAddResponse {
}


export const CouponsAddPage: FC<CouponsAddPageProps> = (props) => {

  const [error, setError] = useState<string>("");
  const history = useHistory();

  const { hub_id } = useParams<{ hub_id: string }>();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
  });

  const handleFormSubmit: SubmitHandler<FormState> = async (data) => {
    // 日付をそのままにしてsubmitするとundefinedになるためデフォルト値を設定する
    let period_start = data.period_start === undefined ? new Date().toISOString() : data.period_start;
    let period_end = data.period_end === undefined ? new Date().toISOString() : data.period_end;
    try {
      const response = await http<CouponsAddResponse>(API_BASE_URL + `/sponsor/hubs/${hub_id}/coupons/create`, "POST",{
        title: data.title,
        description: data.description,
        start: period_start,
        end: period_end,
        stock: data.stock,
        image: data.image,
      });
      console.log("Response ", response.parsedBody)
      history.push(`/sponsor/hubs/${hub_id}/coupons`);
    } catch (ex: any) {
      setError(ex.toString());
    } finally {
    }
  };

  return (
    <CouponsAddForm
    handleFormSubmit={handleFormSubmit}
    handleSubmit={handleSubmit}
    register={register}
    errors={errors}
    getValues={getValues}
    setValue={setValue}
    watch={watch}
    error={error}
    />
  )

};
